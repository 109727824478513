// TODO: AAAAALL of this

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMusic, } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faItchIo, faLinkedin,
    faTwitter, faYoutube,
    faBandcamp, faSoundcloud,
} from '@fortawesome/free-brands-svg-icons'
import {VideosAndScreenshotsViaItchIo} from "../../VideosAndScreenshots_Itch";
import {Link} from "react-router-dom";

class AebfPage extends React.Component {
    constructor(props) {
        super(props);

        this.MusicLinkToLogo = this.MusicLinkToLogo.bind(this);
    }

    componentDidMount() {
        document.title = "AEBF - Ido Waisbart"
    }

    MusicLinkToLogo(link){
        link = link.toLowerCase();
        if (link.includes("bandcamp"))
            return faBandcamp;
        if (link.includes("soundcloud"))
            return faSoundcloud;
        // TODO OPTIONAL: Include Newgrounds logo option
        return faMusic;
    }

    render() {
        return (
            <div className="middle-inner-part-margin-clamped">
                <div style={{display: "flex", alignItems: "center", width: "inherit", height: "80vh",}}>
                    <div style={{width: "55%", height: "100%", display: "flex", alignItems: "center",}}>
                        <div style={{height: "auto",}}>
                            <img src="/Images/My Games/AEBF/AEBF%20Title.png"
                                 alt="AEBF Logo"
                                 style={{height: "15vh",}}/>
                            <div style={{height: "2vh",}}/>
                            <i><h6 style={{fontSize: "0.7em",}}>"A turn-based fighting game, with modding capabilities.<br/>
                                Supported by a <u>.NET Web API</u> server, connected to an <u>MS SQL Server</u> database!<br/>
                                Inspired by the legendary <a href="https://store.steampowered.com/app/432350/Epic_Battle_Fantasy_5/">Epic Battle Fantasy!</a>"
                            </h6></i>
                            <h6 style={{fontSize: "0.7em", color: "#bbb",}}>A game made by: Ido Waisbart,<br/>
                                for the Open University's OOP Workshop.</h6>
                            <hr/>
                            <span style={{fontSize: "0.425em",}}>
                                <p style={{fontSize: "inherit",}}>Inspired by the legendary Epic Battle Fantasy (EBF), I made my finishing project a game like it - But moddable!.</p>
                                <p style={{fontSize: "inherit",}}>Users are given the power to create their own characters, battles and even worlds (Currently, the capabilities are limited).</p>
                                <p style={{fontSize: "inherit",}}>A simple game, packed neatly with a nice flat style, custom music and a little story!</p>
                                <p style={{fontSize: "inherit",}}>May include swearing and pirate-mouths.</p>
                                <br/>
                                <b style={{color: "#b44", fontSize: "inherit",}}>Note: A proper look into the project (F.E., a demo, source code...) will be shared at a later point.</b>
                            </span>
                        </div>
                        <div style={{height: "2.5vh"}}/>
                        <div>
                            <div className="social-media-container"
                                 style={{width: "50%", margin: "auto",}}>
                                {/*<a href="https://github.com/Ido-Waisbart/AEBF"
                                    style={{color: "#6e5494",}}><FontAwesomeIcon icon={faGithub}/></a>*/}
                            </div>
                        </div>
                    </div>
                    <div style={{width: "5%",}}/>
                    <div style={{/*TODO OPTIONAL: Make the ratio less flexible. No vertical-sized YouTube videos!*/
                        width: "40%", height: "90%",
                        display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",
                    }}>
                        {/*TODO: Make VideosAndScreenshots more generic. Have a URLFetchedVideosAndScreenshots child class.*/}
                        <VideosAndScreenshotsViaItchIo ytLink={null} screenshots={[
                            ["/Images/My Games/AEBF/Screenshots/Oi.png", "First Room and Dialogue"],
                            ["/Images/My Games/AEBF/Screenshots/AEBF Swagger.png", ".NET Web API Backend for the Modding System"],
                            ["/Images/My Games/AEBF/Screenshots/AEBF VS Screenshot.png", ".NET Web API Backend in Visual Studio"],
                            ["/Images/My Games/AEBF/Screenshots/AEBF DBeaver.png", "DBeaver look inside the MS SQL Server Database"],
                            ["/Images/My Games/AEBF/Screenshots/AEBF Unity.png", "AEBF inside Unity, Including Editor Tools"],
                            ["/Images/My Games/AEBF/Screenshots/Online Mod List.png", "The Online Mod List"],
                            ["/Images/My Games/AEBF/Screenshots/AEBF Mod Users.png", "The Sign-in and Sign-out Panels"],
                            ["/Images/My Games/AEBF/Screenshots/Triple Bush.png", "The Triple Bush Battle"],
                            ["/Images/My Games/AEBF/Screenshots/Demo Finished.png", "Demo End!"],
                        ]}
                                              style={{width: "100%", height: "56.25%",}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AebfPage;