import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'

class ServiceDescription extends React.Component {
    constructor(props) {
        super(props);

        //this.state = {showChiptunes: false,};  // TODO: There's definitely a better way to handle the showing, right?

        this.CloseChiptunes = this.CloseChiptunes.bind(this);
    }

    // TODO: Put this kind of method in a "ServiceDetails" component.
    CloseChiptunes() {
        console.log("Closing chiptunes");
        this.setState({showChiptunes: false,});
    }

    render() {
        return (
            <div id={this.props.id} className="TotalShadow">
                <div style={{
                    position: "absolute", left: "5vw", top: "5vh",
                    width: "90vw", height: "90vh", margin: 0,
                    background: "#444444bb",
                }}>
                    <div style={{display: "flex", justifyContent: "end",}}>
                        <a style={{
                            color: "#fff", right: 0, position: "relative", width: "3.5vw", height: "3.5vw",
                            display: "flex", justifyContent: "center", alignItems: "center",
                        }}
                           href="#"
                           onClick={this.CloseChiptunes}> {/*TODO OPTIONAL: Make a component out of this? Much more readable.*/}
                            <FontAwesomeIcon icon={faX}/>
                        </a>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{width: "5%"}}/>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "35%"}}>
                            <img src="/Images/Services Images/DotNSF.webp"
                                 alt=".NET"
                                 style={{width: "10vw", imageRendering: "pixelated",}}/>
                            <img src="/Images/Services Images/FamiTracker.webp"
                                 alt="FamiTracker"
                                 style={{width: "10vw",}}/>
                            <img src="/Images/Services Images/Pixel Note Big.webp"
                                 alt="Big Pixel Note"
                                 style={{width: "10vw", imageRendering: "pixelated",}}/>
                            <img src="/Images/Services Images/Pixel Note Medium.webp"
                                 alt="Medium Pixel Note"
                                 style={{width: "5vw", imageRendering: "pixelated",}}/>
                            <img src="/Images/Services Images/Pixel Note Small.webp"
                                 alt="Small Pixel Note"
                                 style={{width: "2.5vw", imageRendering: "pixelated",}}/>
                        </div>
                        <div style={{width: "5%"}}/>
                        <div style={{width: "50%"}}>
                            <h1>Title</h1>
                            <br/>
                            <p>stuff</p>
                        </div>
                        <div style={{width: "5%"}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceDescription;