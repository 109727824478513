//  MyPath.js will take these data elements, and generate nodes out of them.
//  Format:
//      [0] = The Element
//      [1] = Backdrop Image Class Name
//      [2] = Importance Type
//      [3] = Dev links to sources and relevant URIs
//      [4] = Optional title override

// TODO OPTIONAL: Change each node data into a DICTIONARY/JSON, instead of a nameless-element list.

import React from "react";

const ImportanceTypes = {
    Important: 1,
    Normal: 0,
    Unimportant: -1,  // Verbose, verbatim, log, detailed
};

//  Gets two props:
//      The list of three datas of a NodeData: textElement, backdropClassName and importanceType,
//      and style.
const StylizedPathNodeData = (props) => {
    let [textElement, backdropClass, importanceType] = props.nodeData;
    const stylizedComponent = <div style={props.style}>
        {textElement.props.children}</div>
    return(
        [stylizedComponent, backdropClass, importanceType]
    );
}

const NodeDatas = [
    //[textElement, backdropClassName, importantType, devLinks, optionalTitleOverride],
    [<div>
        <p><u>🌱 Sprout of Interest</u></p>
        <p>Life</p>
        <p>2016 - 2018</p>
        <p>I decide to dabble in GameMaker</p>
        <p>Studio 2, Android Studio</p>
        <p>and WinForms to make games</p>
        <p>...</p>
        <p>Oops.</p>
    </div>, null, ImportanceTypes.Important,
    [],],

    [<div>
        <p><u>👨‍🏫 Guidance</u></p>
        <p>Life</p>
        <p>2018</p>
        <p>When I studied in <a href="https://www.magshimim.cyber.org.il/">Magshimim</a>,</p>
        <p>and had to say goodbye to</p>
        <p>my lecturer, he left me a final tip:</p>
        <p>Make the games you've wanted.</p>
        <p>And so, I'm here today. Thanks, Nehoray :)</p>
    </div>, null, ImportanceTypes.Important,
        ["https://www.magshimim.cyber.org.il/"],],

    [<div>
        <p><u>🎩 Mr. Tophat - Birth</u></p>
        <p>Game</p>
        <p>2018 September</p>
        <p>Ideas spurted, and I was excited.</p>
        <p>I was going to make a small game,</p>
        <p>inpired by Cuphead and Undertale,</p>
        <p>and a hint of Mario.</p>
        <p>Endless concepts.</p>
    </div>, null, ImportanceTypes.Normal,
        [],],

    [<div>
        <a href="https://blawnode.itch.io/tutorialjam-rollovergold">
            <p><u>🎓 Tutorial Jam</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2018 November</p>
        <p>I decided to join an event<br/>
            called a
            <i> </i><a href="https://en.wikipedia.org/wiki/Game_jam" className="tooltip-container">"game
                jam"
                <span className="tooltip-text">A time-limited game making competition.</span></a>.</p>
        <p>Found one with "tutorial" in its name, and jumped in.</p>
        <p>One of the best decisions of my life! (Even if the game's lame.)</p>
    </div>, null, ImportanceTypes.Important,
        ["https://blawnode.itch.io/tutorialjam-rollovergold"],],

    [<div>
        <p><u>🎩 Mr. Tophat - Too Much</u></p>
        <p>Game - Unity, C#</p>
        <p>2019</p>
        <p>I love the idea of Mr. Tophat so much.</p>
        <p>... but alas, the idea was too grand.</p>
        <p>When I tried to program it, I could not.</p>
        <p>I was merely a beginner, and I wanted</p>
        <p>to make the next Undertale!</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>🛋️ Co-Op Jam</u></p>
        <p>Game - Unity, C#</p>
        <p>2019 July</p>
        <p>I joined a larger jam, this time!</p>
        <p>A month of development, I decided.</p>
        <p>Couch Game was designed with love, and...</p>
        <p>I've learnt to shrink my scope, once again.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>⌛ Timeless?</u></p>
        <p>Life</p>
        <p>2019 August</p>
        <p>I've been recruited to the army.</p>
        <p>My time has been limited severely.</p>
        <p>I was not content, but eventually...</p>
        <p>I decided it didn't matter.</p>
        <p>The dream is certainly not dead!</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>👀 His Universe</u></p>
        <p>Game - Unity, C#</p>
        <p>2019 September</p>
        <p>I've decided to continue pursuing game development.</p>
        <p>"The time may be halved, but I'll persist!"</p>
        <p>This time, little to no art, a tiny map, 15 minutes of gameplay.</p>
        <p>Overscoped. Wanted to make a whole story with a narrative system.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>👹 Myth of the Abducted</u></p>
        <p>Game - Unity, C#</p>
        <p>Status: 5% complete.</p>
        <p>2020 January</p>
        <p>Another attempt at a game.</p>
        <p>Overscoped, with both a story and a complex game mechanic.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>🔫 Gun Gladiator</u></p>
        <p>Game - Unity, C#</p>
        <p>2020 February</p>
        <p>My first impressive attempt at a game.</p>
        <p>No story, this time!</p>
        <p>And once again, less than 15 minutes of gameplay.</p>
        <p>The project was still quite overwhelming, sadly.</p>
        <p>Despite stopping, it was not the project's end.</p>
    </div>, null, ImportanceTypes.Important,
        [],],

    [<div>
        <p><u>👖 I Need My Pants</u></p>
        <p>Game - Unity, C#</p>
        <p>2020</p>
        <p>An attempt to break out of the norm.</p>
        <p>Tried some 3D, for the fun of it.</p>
        <p>Practiced a story system.</p>
        <p>This was never completed.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>☄️EBF Clone</u></p>
        <p>Game - Unity, C#</p>
        <p>2020</p>
        <p>Ahh, Epic Battle Fantasy. How I adore this game!</p>
        <p>I've forever wanted to attempt making a similar game.</p>
        <p>So I tried, with the tutorials of Code Monkey.</p>
        <p>Tried to built the game over Code Monkey's downloadable project.</p>
        <p>This... was a hard project to follow.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <a href="https://afells.itch.io/bop-placeholder"><p>
            <u>👨‍🚀 The Colonizer</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2020</p>
        <p>Another game jam..! AND, my first team!</p>
        <p>This was a very exciting jam, no longer than a week.</p>
        <p>I made much of the art, but also contributed to the code!</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://afells.itch.io/bop-placeholder"],],

    [<div>
        <p><u>🕹️ Multi-Game</u></p>
        <p>Game - Unity, C#</p>
        <p>2020</p>
        <p>7 games, 7 days.</p>
        <p>I was to make tiny, tiny games!</p>
        <p>It was okay, until I tried to make Mario.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>🎩 Mr. Tophat, again!</u></p>
        <p>Game - Unity, C#</p>
        <p>2021 January</p>
        <p>With more experience under my belt, I retried.</p>
        <p>By now, my concepts were still growing, and became absolutely monumental.</p>
        <p>I managed a basic jump mechanic, but... no more, really.</p>
        <p>Lots of art and music, though!</p>
    </div>, null, ImportanceTypes.Normal,
        [],],

    [<div>
        <a href="https://blawnode.itch.io/tripletriangle">
            <p><u>▲ Triple Triangle</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 - 2022</p>
        <p>I have decided. NOOO more overscoping.</p>
        <p>None of that! No music, no pretty graphics, minimal sounds.</p>
        <p>Only a simple, mobile game, the likes of Ketchapp's games.</p>
        <p>This was a long process. But...</p>
        <p><b>I did it???</b></p>
    </div>, "backdrop-triple-triangle", ImportanceTypes.Important,
        ["https://blawnode.itch.io/tripletriangle"],],

    [<div>
        <p><u>🍅 Men at Foods</u></p>
        <p>Game - Unity, C#</p>
        <p>2021</p>
        <p>Had a fun idea for a 3D game mechanic.</p>
        <p>Eventually unrealised.</p>
        <p>You throw stuff onto other people, like tomatoes,</p>
        <p>and they can catch them.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <a href="https://isochi.itch.io/echoes-of-the-void">
            <p><u>🔦 Echoes of the Void</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 July</p>
        <p>A small jam. I was the musician, for a change..!</p>
        <p>I'm very proud of my work. But eventually, I had</p>
        <p>to become a programmer too, again.</p>
        <p>Despite being too unfair, this game felt like a massive</p>
        <p>success, and was quite loved!</p>
    </div>, null, ImportanceTypes.Normal,
        ["https://github.com/blawnode/Mixed-Bag-s-game",
        "https://isochi.itch.io/echoes-of-the-void"],],

    [<div>
        <a href="https://blawnode.itch.io/temporary-pizza-mens-game">
            <p><u>🚦 Light Speed Delivery</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 August</p>
        <p>The fabled Brackeys Jam!</p>
        <p>And, one of the best products I've been a part of!</p>
        <p>An artist, this time. We had two programmers,</p>
        <p>and this time, they managed fine without me.</p>
        <p>The art and sound got nice rankings!</p>
    </div>, null, ImportanceTypes.Important,
        ["https://blawnode.itch.io/temporary-pizza-mens-game"],],

    [<div>
        <a href="https://blawnode.itch.io/polykiller">
            <p><u>■ PolyKiller</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 September</p>
        <p>My first TriJam. This is a very fun kind of jam!</p>
        <p>3 hours. No more, no less.</p>
        <p>Our programmer went M.I.A, so I became it.</p>
        <p>Quite belatedly, sadly.</p>
        <p>The game's code was terribly behind in schedule.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://blawnode.itch.io/polykiller"],],

    [<div>
        <p><u>🔫 Gun Gladiator, Revisited!</u></p>
        <p>Game - Unity, C#</p>
        <p>2021 September</p>
        <p>I decided to mean business.</p>
        <p>After so many jams? "I'm DEFINITELY ready!"</p>
        <p>I was joined by a new friend, the musician.</p>
        <p>And I did the rest, mostly code and art.</p>
        <p>Frankly... I was still not ready. But, lots of progress was made, this time.</p>
    </div>, null, ImportanceTypes.Normal,
        [],],

    [<div>
        <a href="https://blawnode.itch.io/of-bananas">
            <p><u>🍌 Of Bananas</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 October</p>
        <p>Another TriJam.</p>
        <p>I decided to experiment with Inkle's Ink.</p>
        <p>Worked shockingly perfectly, but the game was a bit...</p>
        <p>underwhelming, it seems.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://blawnode.itch.io/of-bananas"],],

    [<div>
        <p><u>👋 Be My Guest</u></p>
        <p>Game - Godot, Python(/GDScript?)</p>
        <p>2021 November</p>
        <p>My first attempt at Godot.</p>
        <p>I decided to make a little RPG, to polish my coding skills.</p>
        <p>Tried to make a story system again, and a basic interaction system.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <a href="https://blawnode.itch.io/spreading-the-cold">
            <p><u>❄️ Spreading the Cold</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 November</p>
        <p>The IGU Winter Jam! I am fond of it.</p>
        <p>I was joined by a digital friend and a stranger friend,</p>
        <p>and I made the code alone this time!</p>
        <p>Despite being a little broken, the game did okay!</p>
        <p>And the feats were somewhat impressive.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://blawnode.itch.io/spreading-the-cold"],],

    [<div>
        <a href="https://blawnode.itch.io/codegunner">
            <p><u>🤖 Code Gunner</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2021 December</p>
        <p>The JLM Jam. My first Israeli jam..!</p>
        <p>I did not attend physically, but I couldn't pass the opportunity.</p>
        <p>I made a little game, and it was great!</p>
        <p>There was no voting and I didn't hear about how my game was received,</p>
        <p>But I was very satisfied.</p>
    </div>, null, ImportanceTypes.Normal,
        ["https://blawnode.itch.io/codegunner"],],

    [<div>
        <p><u>🛏️ I Leave the Bed</u></p>
        <p>Game - Godot, Unity, C#</p>
        <p>2022 February</p>
        <p>A Unity RPG.</p>
        <p>A more personal game.</p>
    </div>, null, ImportanceTypes.Unimportant,
        [],],

    [<div>
        <p><u>🧙 Everhood's Custom Battle Contest</u></p>
        <p>Game - Unity</p>
        <p>2022 February</p>
        <p>Ah, Everhood. Love it to bits.</p>
        <p>We were to mod the game, pretty much.</p>
        <p>Each team made a musical battle!</p>
        <p>My submission, The Cackler, was not very well received.</p>
        <p>But I was, and still am, so proud of it.</p>
    </div>, null, ImportanceTypes.Normal,
        [/*TODO*/],],

    [<div>
        <a href="https://blawnode.itch.io/low-disk-space">
            <p><u>👾 Low Disk Space</u></p></a>
        <p>Game - Godot, Python(/GDScript?)</p>
        <p>2022 March</p>
        <p>The GDC jam.</p>
        <p>My first finished project made with Godot!</p>
        <p>Teamed with a programmer, and another, that could not join us.</p>
        <p>I did the art, music, and some of the programming.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://blawnode.itch.io/low-disk-space"],],

    [<div>
        <a href="https://blawnode.itch.io/adrift-jam">
            <p><u>🔋 A Jam with BN #1</u></p></a>
        <p>Game - Unreal Engine</p>
        <p>2022 May</p>
        <p>The most meaningful event I've ever had the pleasure</p>
        <p>of being a part of. I hosted a game jam!</p>
        <p>5 friends and strangers joined me, and we made a slightly broken,</p>
        <p>but beloved, 2D game.</p>
        <p>First (and only) experience with Unreal Engine!</p>
    </div>, "backdrop-adrift", ImportanceTypes.Important,
        ["https://blawnode.itch.io/adrift-jam"],],

    [<div>
        <a href="https://store.steampowered.com/app/2606610/Adrift_Program/">
            <p><u>🔋 Adrift Program</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2022 May - Present</p>
        <p>The "A Jam with BN #1" jam was great fun.</p>
        <p>The team decided to continue developing the game!</p>
        <p>We have been making and polishing this game for <i>months</i>, now!</p>
        <p>Couldn't be more proud of this creation.</p>
    </div>, "backdrop-adrift", ImportanceTypes.Important,
        ["https://blawnode.itch.io/adrift",
        "https://store.steampowered.com/app/2606610/Adrift_Program/"],],


    /*TODO: <iframe src="https://discord.com/widget?id=1029820838188421130&theme=dark"
                width="350" height="500" allowtransparency="true" frameborder="0"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>*/

    [<div>
        <a href="https://github.com/blawnode/The-Rock-Simulator">
            <p><u>⛰️The Rock Simulator</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2022 July</p>
        <p>Another attempt at an EBF clone.</p>
        <p>But this time... it was <i>flawless</i>.</p>
        <p>I published this project as an open source GitHub repository!</p>
    </div>, null, ImportanceTypes.Normal,
        ["https://github.com/blawnode/The-Rock-Simulator"],],

    [<div>
        <a href="https://github.com/blawnode/WDYM">
            <p><u>👊 WHAT DO YOU MEAN?!</u></p></a>
        <p>Game - Unity, C#</p>
        <p>2022 September</p>
        <p>Another TriJam, and I delivered BIG time!</p>
        <p>With art and music assets ready to be unleashed, I was pumped.</p>
        <p>Made a tiny, PUNCHing game, that looks awesome.</p>
        <p>... wish it scored higher though.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://github.com/blawnode/WDYM"],],

    [<div>
        <a href="https://github.com/blawnode/Blawsole-and-SprintRPG">
            <p><u>🕹️ Blawsole & SprintRPG</u></p></a>
        <p>Game - PyGame, Python</p>
        <p>2022 November</p>
        <p>I adore Nitrome's SprintRPG.</p>
        <p>... So I remade it. Nothing professional, though!</p>
        <p>A little PyGame console, with this fast-paced,
            easy to modify key game.</p>
    </div>, null, ImportanceTypes.Unimportant,
        ["https://github.com/blawnode/Blawsole-and-SprintRPG"],],

    [<div>
        <p><u>⚛ freeCodeCamp Course</u></p>
        <p>2022 June - 2022 December</p>
        <p>Education</p>
        <p>I have officially complete freeCodeCamp's course!:</p>
        <p>"Front End Development Libraries".</p>
        <p>I even got a neat <a
            href="https://www.freecodecamp.org/certification/idowaisbart/front-end-development-libraries">
            certification</a>!</p>
        <p>Meaning: I studied React and other libraries,</p>
        <p>And made several little web projects.</p>
    </div>, "backdrop-freecodecamp", ImportanceTypes.Important,
        ["https://www.freecodecamp.org/certification/idowaisbart/front-end-development-libraries"],],

    [<div>
        <p><u>⚛ Personal Website</u></p>
        <p>2022 September - Present</p>
        <p>Webpage - React.js</p>
        <p>After studying in freeCodeCamp's course, I felt ready to make a GOOD website!</p>
        <p>And so, I've begun working.</p>
        <p>Lo and behold!:</p>
        <p>You are seeing it right now.</p>
    </div>, "backdrop-website", ImportanceTypes.Important,
        ["https://www.idow.xyz"],],

    [<div>
        <a>
            <p><u>🟨 AEBF (Another Epic Battle Fantasy)</u></p></a>
        <p>2023 April - 2023 December</p>
        <p>Game, Server - Unity, .NET API, MS SQL Server</p>
        <p>My finishing project for the Open University's OOP workshop!</p>
        <p>Uses The Rock Simulator RPG framework, as well as is an upgrade to it!</p>
        <p>A flexible, extendable game with a full-stack modding and user system.</p>
        <p>Heavily inspired by Epic Battle Fantasy, by Matt Roszak.</p>
    </div>, "backdrop-aebf", ImportanceTypes.Important,
        ["https://www.linkedin.com/posts/ido-waisbart_aebf-unityweb-apims-sql-open-university-activity-7167163529299496960-81SD?utm_source=share&utm_medium=member_desktop"],],

    [<div>
        <a href="https://blawnode.itch.io/insomniac-moles">
            <p><u>🕳 Insomniac Moles</u></p></a>
        <p>2023 July</p>
        <p>Game - Unity</p>
        <p>A collab with a friend, made in 3 hours for the GMTK Game Jam 2023!</p>
        <p>A reverse Whack-A-Mole game: You play as the moles, and hide for your lives!</p>
        <p>But not for too long. Your non-existent eyes need some sunlight!</p>
        <p>Played with a numpad.</p>
    </div>, "backdrop-insomniac-moles", ImportanceTypes.Important,
        ["https://blawnode.itch.io/insomniac-moles"],],

    [<div>
        <a href="https://blawnode.itch.io/perception-to-conquer-evil">
            <p><u>⚔ Perception: To Conquer Evil</u></p></a>
        <p>2023 August</p>
        <p>Game - Unity, C#, FMod</p>
        <p>A submission to the IGU Summer Jam 2023, made in 14 days!</p>
        <p>A small slashing action game, with some story and voice acting!</p>
        <p>Involves 3 "boss" fights, and some easter eggs.</p>
        <p>Find me!</p>
    </div>, "backdrop-perception", ImportanceTypes.Important,
        ["https://blawnode.itch.io/perception-to-conquer-evil"],],

    [<div>
        <a href="https://blawnode.itch.io/blank-space">
            <p><u>💡 <b>_</b> Space</u></p></a>
        <p>2024 January</p>
        <p>Game - Unity, C#</p>
        <p>A personal jam game for the IGU Winter Jam 2024. (Won 3rd place out of 17!)</p>
        <p>While not all features were complete, polishing was at an all time high!</p>
        <p>Story-driven, short and simple. With a hint of shooting.</p>
        <p>Destined to be made an even bigger game.</p>
    </div>, "backdrop-_-space", ImportanceTypes.Normal,
        ["https://blawnode.itch.io/blank-space"],
        "_ Space"]
];

//export StylizedPathNodeElement;
export {
    NodeDatas,
    StylizedPathNodeData,
    ImportanceTypes,
}
export default NodeDatas;