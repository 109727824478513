import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";

class Resume extends React.Component {
    render() {
        return (
            <object data={this.props.file} type="application/pdf" style={{...this.props.style, width: "90%", height: "180vh"}}>
                <p>Alt. text - include a link <a href="/https://media.tenor.com/images/2898a5473497bdcce06e882f4504c130/tenor.png">to the PDF!</a></p>
            </object>
        );
    }
}

class MyResumeContainer extends React.Component {
    languages = ["English", "Hebrew"];

    constructor(props) {
        super(props);
        this.state = {
            language: "English",
            languageButtonElement: "",
        };

        this.onClickChangeLanguage = this.onClickChangeLanguage.bind(this);
        this.getNextLanguage = this.getNextLanguage.bind(this);
        this.generateLanguageButtonElement = this.generateLanguageButtonElement.bind(this);
    }

    componentDidMount() {
        this.setState({
            languageButtonElement: this.generateLanguageButtonElement(),
        })
    }

    getNextLanguage(){
        return (this.state.language === "English") ? "Hebrew" : "English";
    }

    onClickChangeLanguage(){
        let newLanguage = this.getNextLanguage();
        this.setState({language: newLanguage,
            languageButtonElement: this.generateLanguageButtonElement(newLanguage)})
    }

    // https://www.geeksforgeeks.org/function-overloading-in-javascript/
    generateLanguageButtonElement() {
        let language = this.state.language;
        if(arguments.length === 1){
            language = arguments[0];
        }

        console.log(language);
        let isEng = language === "English";
        return <span>
            <span style={{color: isEng ? "#000" : "#888"}}>English</span>
            /
            <span style={{color: !isEng ? "#000" : "#888"}}>Hebrew</span>
        </span>;
    }

    render() {
        return (
            <div>
                <div style={{margin: "5vh auto", width: "fit-content"}}>
                    <button onClick={this.onClickChangeLanguage}
                        style={{fontSize: "20px"}}>
                        <FontAwesomeIcon icon={faGlobe} style={{marginRight: "8px"}}/>
                        {this.state.languageButtonElement}
                    </button>
                </div>
                <div style={{display: "flex", justifyContent: "space-around"}}>
                    {/*<Resume file="/Misc Documents/Resume - Ido Waisbart (English).pdf" language="English"
                            style={{visibility: this.state.language === "English" ? "visible" : "hidden"}}/>
                    <Resume file="/Misc Documents/Resume - Ido Waisbart (Hebrew).pdf" language="Hebrew"
                            style={{visibility: this.state.language === "Hebrew" ? "visible" : "hidden"}}/>*/}
                    {
                        this.state.language === "English" ?
                            <Resume file="/Misc Documents/Resume - Ido Waisbart (English).pdf" language="English"/>
                            : <Resume file="/Misc Documents/Resume - Ido Waisbart (Hebrew).pdf" language="Hebrew"/>
                    }
                </div>
            </div>
        );
    }
}

export { Resume, MyResumeContainer };