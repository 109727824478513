import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import MyPath from "../MyPath";
import {Link} from "react-router-dom";
import {MePics} from "../MePics";

class AboutPage extends React.Component {
    componentDidMount(){
        document.title = "About Me - Ido Waisbart"
    }

    render() {
        return (
            <div className="MyPage middle-inner-part-margin">
                <article style={{margin: "0 0 50px 0"}}>
                    <div style={{padding: "5% 0 10% 0"}}>
                        {/*The <MePics/>'s position should be handled with a grid, instead. Probably.
                            UNLESS I want the pics behind or in front of the text.*/}
                        <MePics style={{top: "12.5vh", left: "55vw"}} picSize="30vh" pageStyle="me-pics-size-about"/>
                        <div className="article-content-wrapper" style={{position: "relative", width: "50vw", height: "56vh"}}>
                            <p>I am Ido Waisbart, a creative.</p>
                            <p>I develop <i>software</i> as a profession, like
                                <i> </i><Link to="/aboutwebsite" className="tooltip-container">React.js websites
                                    <span className="tooltip-text" style={{fontSize: "large"}}>Like this website!</span>
                                </Link><i> </i>
                                and the occasional <span className="tooltip-container">.NET desktop applications
                                    <span className="tooltip-text"
                                          style={{fontSize: "large"}}>C#, WPF and WinForms</span>
                                </span>,</p>
                            <p>And, I also made many <span className="pixel-font"
                                                                style={{fontSize: "24px"}}>games
                            </span>,
                                and game-related creations:</p>
                            <ul className="game-related-creation-list">
                                <li>I program games. I have made many games with
                                    {/*TODO OPTIONAL: Make a <space/> component, which's just <i> </i>. Readability!*/}
                                    <i> </i><a href="https://unity.com/" className="tooltip-container">Unity
                                        <span className="tooltip-text">A very popular, free C#-based game engine.</span></a> and C#. I've also used
                                    <i> </i><a href="https://godotengine.org/" className="tooltip-container">Godot
                                        <span className="tooltip-text">A popular, free and open-source GDScript/Python/C#-based game engine.</span></a>
                                    <i> </i>a few times and
                                    <i> </i><a href="https://www.unrealengine.com/" className="tooltip-container">Unreal Engine 5
                                        <span className="tooltip-text">A very popular, free C++-based game engine.</span></a>
                                    <i> </i>once, but I'm not used to these.
                                </li>
                                <li>I draw and animate pixels with
                                    <i> </i><a href="https://www.aseprite.org" className="tooltip-container">Aseprite
                                        <span className="tooltip-text">A popular, paid pixel art and animation editor.</span>
                                    </a>.
                                </li>
                                <li>I compose and play music. Most notably, I arrange chiptunes/8-bit music with
                                    <i> </i><a href="http://famitracker.com/" className="tooltip-container">FamiTracker
                                        <span className="tooltip-text">A free chiptune tracker. Makes 8-bit, retro music, like Super Mario's.</span>
                                    </a>.
                                </li>
                            </ul>
                        </div>

                        <div style={{height: "1em"}}/>

                        <div className="start-flag">
                            <a href={"#🌱 Sprout of Interest"} title="Start viewing My Path"> {/*TODO OPTIONAL: Make a component out of this? Much more readable.*/}
                                <FontAwesomeIcon icon={faFlag} />
                            </a>
                        </div>
                    </div>

                    <MyPath/>
                </article>
            </div>
        );
    }
}

export default AboutPage;