import React from "react";
import {Link} from "react-router-dom";

class AboutWebsitePage extends React.Component {
    componentDidMount(){
        document.title = "Home - Ido Waisbart"
        this.OnClickStartFlag = this.OnClickStartFlag.bind(this);
    }

    OnClickStartFlag(){
        console.log("Hahaaa");
    }

    // A primitive copy-paste of MyNavbar.js. Should move into a separate script?
    isMobile(){
        return window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
    }

    render() {
        let isMobile = this.isMobile();
        let techIconSize = isMobile ? "17.5vw"  // because there's 100vw, minus about 20vw of margin, minus some spacing = the width of 4 icons
            : "20vh";
        let techIconSizeLarger = isMobile ? "35vw"  // because there's 100vw, minus about 20vw of margin, minus some spacing = the width of 2 icons
            : "40vh";
        let containerMargin = isMobile ? 0 : "auto";
        //let containerHeight = isMobile ? "5vh" : "40vh";
        let containerLargerHeight = techIconSizeLarger;
        let containerHeight = techIconSize;
        let containerWidth = isMobile ? "100%" : "70%";

        /*TODO: Proper .css-ing*/
        return (
            <div className="MyPage middle-inner-part-margin"
                 style={{height: "100%"}}>
                <article style={{margin: "0", height: "100%"}}>
                    <div className="article-content-wrapper large-font-inside">

                        <p style={{textAlign: "center", marginBottom: "10vh"}}>This website was made with:</p>

                        <div style={{
                            position: "relative", margin: containerMargin, width: containerWidth, height: containerLargerHeight,
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                        }}>
                            <img src="/Images/Tech Icons/React/logo512.webp"
                                 alt="React Logo"
                                 style={{width: techIconSizeLarger, height: techIconSizeLarger,}}
                                 className="dropShadow"/>
                            <img src="/Images/Tech Icons/WebStorm.webp"
                                 alt="WebStorm Logo"
                                 style={{width: techIconSizeLarger, height: techIconSizeLarger,}}
                                 className="dropShadow"/>
                        </div>
                        <div style={{
                            position: "relative", margin: containerMargin, width: containerWidth,
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                        }}>
                            <p style={{width: techIconSizeLarger, fontSize: "2vw", textAlign: "center",}}>React.js</p>
                            <p style={{width: techIconSizeLarger, fontSize: "2vw", textAlign: "center",}}>WebStorm</p>
                        </div>

                        <div style={{height: "5vh"}}/>

                        <p style={{textAlign: "center", marginBottom: "10vh"}}>I also explored usage of the PERN stack:</p>

                        <div style={{
                            position: "relative", margin: containerMargin, width: containerWidth, height: containerHeight,
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                        }}>
                            <img src="/Images/Tech Icons/Pgsql.webp"
                                 alt="PgSQL Logo"
                                 style={{width: techIconSize, height: techIconSize,}}
                                 className="dropShadow"/>
                            <img src="/Images/Tech Icons/Express Black BG.webp"
                                 alt="Express Logo"
                                 style={{width: techIconSize, height: techIconSize,
                                     filter: "drop-shadow(0 0 2.5px black)",}}
                                 className="dropShadow"/>
                            <img src="/Images/Tech Icons/React/logo512.webp"
                                 alt="React Logo"
                                 style={{width: techIconSize, height: techIconSize,}}
                                 className="dropShadow"/>
                            <img src="/Images/Tech Icons/Node.webp"
                                 alt="Node.js Logo"
                                 style={{width: techIconSize, height: techIconSize,}}
                                 className="dropShadow"/>
                        </div>
                        <div style={{
                            position: "relative", margin: containerMargin, width: containerWidth,
                            display: "flex", justifyContent: "space-between", alignItems: "center",
                        }}>
                            <p style={{width: techIconSize, fontSize: "1vw", textAlign: "center",}}>PostgreSQL</p>
                            <p style={{width: techIconSize, fontSize: "1vw", textAlign: "center",}}>Express.js</p>
                            <p style={{width: techIconSize, fontSize: "1vw", textAlign: "center",}}>React.js</p>
                            <p style={{width: techIconSize, fontSize: "1vw", textAlign: "center",}}>Node.js</p>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default AboutWebsitePage;