// Expected to be an abstract class, to be inherited from. (See VideosAndScreenshots_Itch.jsx)

// TODO: Test the three HTTP Request methods: Fetch, Axios & Cheerio and itch-scraper.
//  Decide which to use, and uninstall what's unused.
//  Note: itch-scraper CONTAINS the method of Axios & Cheerio.

// If getScreenshotsFrom is defined, then attempt to access the URL's page's screenshots.
// Else, or if the URL access failed, just load <screenshots> - even if it's empty.

/* Usage:
<VideosAndScreenshots screenshots={[["/Images/Blawnode%20Logo%20BG-less.webp", "Wide Blawnode"],]}
                      style={{width: "100%", height: "56.25%",}}/>
<VideosAndScreenshots getScreenshotsFrom="https://blawnode.itch.io/adrift"
                      style={{width: "100%", height: "56.25%",}}/>
 */

import React from "react";
import {Carousel} from "react-bootstrap";
/*import axios from 'axios';
import * as cheerio from "cheerio";
import scraper from 'itch-scraper';*/

// https://github.com/DeathVenom54/itch-scraper/blob/0b1aade2a8b1fd8b39f68058a184b5373fa67563/src/helpers/loadPage.ts#L4
// Assumption: url is a string.
/*export const loadPage = async (url) => {
    const page = await axios.get(url).catch((err) => {
        throw new Error(`[!] Error trying to fetch page: ${err.message}`);
    });

    return cheerio.load(page.data);
};

// https://github.com/DeathVenom54/itch-scraper/tree/0b1aade2a8b1fd8b39f68058a184b5373fa67563
let scrapeScreenshots = async (url: string) => {
    try {
        // Some places online suggest a proxy to bypass the CORS issue?
        //let proxyURL = "https://cors-anywhere.herokuapp.com/" + url;
        //console.log("Proxy: " + proxyURL);
        //const gameScreenshots = await scraper.getGameScreenshots(proxyURL);  // Get the urls of screenshots uploaded by the author. Empty array if none
        const gameScreenshots = await scraper.getGameScreenshots(url);  // Get the urls of screenshots uploaded by the author. Empty array if none
        console.log(`Found ${gameScreenshots.length} game screenshots at ${url}.`);
    } catch (err) {
        console.error("HAHA " + err.toString());
    }
};*/

class VideosAndScreenshots extends React.Component {
    sameSizeStyle = {width: "100%", height: "100%",};
    //sameSizeStyleImg = {width: "max-content", height: "100%",};
    sameSizeStyleImg = {width: "100%",};

    /*componentDidMount() {
        // I want to find the <div/> with the id 'screenshot_list', and get all of its children.
        // They will all be <a/>'s, with a href to a screenshot URL.
        // I want to fetch all of those URLs.

        // https://stackoverflow.com/questions/45980173/react-axios-network-error
        // REMEMBER: This should logically not work on localhost.
        // TODO: BUT, according to Sasha, I can use Express to bypass this, by doing: "node BNApp.js" in CMD.

        let screenshotsSourceURL = this.props.getScreenshotsFrom
        if (screenshotsSourceURL === null)
            return;
        console.log(`Fetching ${screenshotsSourceURL}...`);
        /*fetch(screenshotsSourceURL)
            .then(response => {
                switch (response.status) {
                    // status "OK"
                    case 200:
                        return response.text();
                    // status "Not Found"
                    case 404:
                        throw response;
                    default:
                        throw new Error("ERROR: Unhandled HTTP request status.");
                }
            })
            .then(data => {
                    console.log(data.total);
                    //this.setState({totalReactPackages: data.total});
                }
            )
            .catch((error) => {
                console.log(error)
            });* /

        //let page = loadPage(screenshotsSourceURL);
        //console.log(page);

        let scrapedScreenshots = scrapeScreenshots(screenshotsSourceURL);
        console.log(scrapedScreenshots);
    }*/

    render() {
        return (
            <Carousel className="customCarousel" interval={null} style={this.props.style}>
                {this.props.ytLink != null && <Carousel.Item style={this.sameSizeStyle}>
                    <iframe title="Game Youtube Video" src={this.props.ytLink}
                            style={this.sameSizeStyle}/>
                </Carousel.Item>}
                {this.props.screenshots.map((screenshot, index)=>
                {
                    // A screenshot consists of a src ([0]), and an alt ([1]).
                    let src = screenshot[0], alt = screenshot[1];
                    return <Carousel.Item style={this.sameSizeStyleImg} key={index}>
                        <img
                            src={src}
                            alt={alt}
                            style={this.sameSizeStyleImg}
                        />
                    </Carousel.Item>;}
                )}
            </Carousel>
        );
    }
}

VideosAndScreenshots.defaultProps = {
    ytLink: "https://www.youtube.com/embed/XIMLoLxmTDw?autoplay=0&mute=0&controls=0",
    screenshots: [],
    //getScreenshotsFrom: null,
    style: {},
}

export { VideosAndScreenshots };