import React from "react";
import {
    //NavLink as Link,  // TODO: The navbar's links are highlighted in an unappealing manner.
    Link, Route,
} from "react-router-dom";
//import useCheckMobileScreen from "../../useCheckMobileScreen";
import './MyNavbar.scss';

// faCircle of free-regular
// faTimes of free-solid
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX as faClose, faBars as faMenu} from "@fortawesome/free-solid-svg-icons";

// https://react-bootstrap.github.io/components/dropdowns/
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import SignInUpDialog from "./SignInUpDialog";
import {Auth} from "aws-amplify";

import websiteWipStatus from 'Data and JSONs/WebsiteWipStatus.json';

class MyNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.isMobile = this.isMobile.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.signInUpDialogCloseMethod = this.signInUpDialogCloseMethod.bind(this);
        this.logOut = this.logOut.bind(this);

        /*console.log("Before: " + this.state.showMenu)
        this.setState({showMenu: !this.isMobile()});  // Desktop? Show! Mobile? Hide! (At first)
        console.log("After: " + this.state.showMenu)*/

        this.state = {
            height: 0,
            showMenu: !this.isMobile(),  // Desktop? Show! Mobile? Hide! (At first)
            isSignedIn: false,
            showSignUpInDialog: false,
        };

        //console.log("After: " + this.state.showMenu)
    }

    // https://stackoverflow.com/questions/39435395/reactjs-how-to-determine-if-the-application-is-being-viewed-on-mobile-or-deskto
    isMobile(){
        //return useCheckMobileScreen();
        var isMobile = window.matchMedia && window.matchMedia("(max-width: 600px)").matches;
        // console.log("isMobile calculated: " + isMobile);
        return isMobile;
        //return window.screen.width <= 800;
    }

    toggleMenu(){
        this.setState(
            {showMenu: !this.state.showMenu}
        );
    }

    componentDidMount() {
        this.setState({
            // https://stackoverflow.com/questions/3974521/get-current-height-of-element-by-id
            height: (!this.isMobile()) ?
                document.getElementById('navbar-table').offsetHeight
                : 0,
        });
    }

    signInUpDialogCloseMethod(){
        this.setState({showSignUpInDialog: false});
    }

    async logOut() {
        try {
            await Auth.signOut();
            console.log("Successfully logged out!");
            this.setState({isSignedIn: false, showSignUpInDialog: false,});
        } catch (error) {
            console.log('Error logging out: ' + error.toString());
        }
    }

    render() {
        return (
            <header>
                {/*this.state.showMenu*/}
                <div className={"hideable-menu" + (this.state.showMenu ? ' hideable-menu_active' : '')}>
                    <div className="navbar-container">
                        <nav id='navbar-table' className={'navbar-table'}>
                            <Link to="/" onClick={this.isMobile() ? this.toggleMenu : null}
                                  className="navbar-item">{/*activeStyle>*/}Home</Link>
                            {/*<a className="tooltip" href="" onClick={idk}>Home (AHH)
                            <span className="tooltip-text">DON'T DO IT! IT REFRESHES THE PAGE! AAAAAH! THAT'S SO NOT REACT!</span>
                        </a>*/}
                            {/*<Link to="/portfolio" onClick={this.isMobile() ? this.toggleMenu : null}
                           className="navbar-item">Portfolio</Link>*/}
                            {/*<Link to="/games" onClick={this.isMobile() ? this.toggleMenu : null}
                           className="navbar-item">Games</Link>*/}

                            <Dropdown className="navbar-item navbar-item-container">
                                <Dropdown.Toggle variant="secondary" as={"a"}
                                    /*Issue noticed:
                                    * Setting the href of some React components to javascript:void(0) causes
                                    * the error "eslint: script url is a form of eval"
                                    * Solution found at:
                                    * https://github.com/facebook/react/issues/16592
                                    * The solution lets the dropdown be "properly clicked on" -
                                    * The correct cursor symbol would appear,
                                    * and the correct style would be applied*/
                                    //href="" onClick={e => {e.preventDefault(); super.onClick();}}
                                    //href="javascript:void(0)"
                                                 href="#nolink"
                                                 style={{display: "inherit"}}
                                                 className="navbar-item"
                                >
                                    About
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant="dark" style={{width: "100%"}}>
                                    <Dropdown.Item as={Link} to="/about"
                                                   onClick={this.isMobile() ? this.toggleMenu : null}
                                    >
                                        Me</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item as={Link} to="/aboutwebsite"
                                                   onClick={this.isMobile() ? this.toggleMenu : null}
                                    >
                                        Website</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            {/*<Link to="/aboutwebsite" onClick={this.isMobile() ? this.toggleMenu : null}
                          className="navbar-item">About Website</Link>*/}
                            {/*<Link to="/about" onClick={this.isMobile() ? this.toggleMenu : null}
                          className="navbar-item">About Me</Link>*/}

                            <Dropdown className="navbar-item navbar-item-container">
                                <Dropdown.Toggle variant="secondary" as={"a"}
                                                 href="#nolink"
                                                 style={{display: "inherit"}}
                                                 className="navbar-item"
                                >
                                    Hire
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant="dark" style={{width: "100%"}}>
                                    <Dropdown.Item as={Link} to="/hireme"
                                                   onClick={this.isMobile() ? this.toggleMenu : null}
                                    >
                                        Hire Profile</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    {/*<Dropdown.Item as={Link} to="/services"
                                           onClick={this.isMobile() ? this.toggleMenu : null}
                            >
                                Services</Dropdown.Item>*/}
                                    {/*TODO: Restore the services page after it's complete.*/}
                                    {/*TODO: Make a dropdown inside this dropdown, named "services". In it: Pixel art, chiptunes, ...*/}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown className="navbar-item navbar-item-container">
                                <Dropdown.Toggle variant="secondary" as={"a"}
                                                 href="#nolink"
                                                 style={{display: "inherit"}}
                                                 className="navbar-item"
                                >
                                    My Creations
                                </Dropdown.Toggle>

                                <Dropdown.Menu variant="dark" style={{minWidth: "100%"}}>
                                    <Dropdown.Item as={Link} to="/games/adrift"
                                                   onClick={this.isMobile() ? this.toggleMenu : null}
                                    >
                                        Adrift Program (Game)</Dropdown.Item>

                                    <Dropdown.Item as={Link} to="/games/aebf"
                                                   onClick={this.isMobile() ? this.toggleMenu : null}
                                    >
                                        AEBF (Full-Stack Game)</Dropdown.Item>
                                    {/*TODO: Make dropdowns inside this dropdown, named: Games, pixel art, chiptunes, ...*/}
                                </Dropdown.Menu>
                            </Dropdown>

                            {/*<Link to="/blogs" onClick={this.isMobile() ? this.toggleMenu : null}
                           className="navbar-item">Blogs</Link>*/}
                            {/*<Link to="/community" onClick={this.isMobile() ? this.toggleMenu : null}
                          className="navbar-item">Community</Link>*/}
                            {/*<Link to="/editprofile" onClick={this.isMobile() ? this.toggleMenu : null}
                           className="navbar-item">[Edit your Profiles]</Link>{/* TODO: Put this elsewhere.**}*/}
                            <Link to="/contact" onClick={this.isMobile() ? this.toggleMenu : null}
                                  className="navbar-item">Contact</Link>
                            <Link to="/tobeadded" onClick={this.isMobile() ? this.toggleMenu : null}
                                  className="navbar-item">To Be Added</Link>
                            {/*<Link to="/testbox" onClick={this.isMobile() ? this.toggleMenu : null}
                           className="testbox-link navbar-item">TESTBOX</Link>*/}
                        </nav>

                        <div style={{width: "fit-content",}} className="push-center-if-mobile">
                            <p className="p-small" style={{color: "#fff", paddingLeft: "16px"}}>Currently in progress:</p>
                            <p className="p-small" style={{color: "#fff", paddingLeft: "16px"}}>
                                {websiteWipStatus.itemInProgress} - {websiteWipStatus.progressAmount}</p>
                        </div>

                        {/*<div style={{width: "15%", height: "2.5vh"}}/>*/}
                        {false && <div style={{margin: "0 2vw 0 auto", width: "fit-content", height: "100%",
                            display: "flex", alignItems: "center",}}>
                            {(!this.state.isSignedIn && <>
                                {/*<p className="p-small" style={{color: "#fff"}}>Sign up/in</p>*/}

                                {/*TODO: Hide this after you're done testing this's dropdown.*/}

                                <Dropdown style={{height: "100%", width: "fit-content", marginRight: "8px",}}>
                                    {/*<Dropdown.Toggle style={{width: "100%", height: "100%", margin: 0,}}>*/}
                                    {/*    <img src="/Images/EPIC AD.webp" alt="Profile Picture"*/}
                                    {/*         style={{width: "100%", height: "100%",}}*/}
                                    {/*         className="profile-icon"*/}
                                    {/*         />*/}
                                    {/*</Dropdown.Toggle>*/}
                                    {/*<ImageToggle style={{width: "100%", height: "100%", margin: 0,}}/>*/}
                                    <div style={{height: "100%",
                                        display: "flex", justifyContent: "center", alignItems: "center",}}>
                                        <Dropdown.Toggle as={BlankToggle}
                                                         style={{height: "100%", padding: "0 4px",
                                                             display: "flex", alignItems: "center",}}
                                                         className="header-button">  {/* Used to have `className="no-hover-bgcolor"
                                                 Also, eventually ditched the idea of only an icon button (borderRadius: "50%"),
                                                 in favor of a navbar-looking rectangular button.*/}
                                            {/*<Dropdown.Toggle title={<img src={"/Images/EPIC AD.webp"} alt="empty"/>}*/}
                                            {/*                 style={{height: "66%", marginRight: "8px", borderRadius: "50%",}}>*/}
                                            <img src="/Images/EPIC AD.webp" alt="Profile Dropdown Icon"
                                                 className="profile-icon"
                                                 style={{marginRight: "8px", }}/>
                                            <p style={{fontSize: "1vw",}}>EEEPIC AD</p>
                                        </Dropdown.Toggle>
                                    </div>

                                    {/*https://reactjsexample.com/a-simple-dropdown-menu-for-react-with-bootstrap-icon-trigger/*/}
                                    {/*<div style={{height: "100%", marginRight: "8px",*/}
                                    {/*    display: "flex", justifyContent: "center", alignItems: "center",}}>*/}
                                    {/*<img src="/Images/EPIC AD.webp" alt="Profile Dropdown Icon"
                                         className="profile-icon"
                                         style={{height: "66%",}}
                                        onClick={()=>{
                                        console.log("Damn nice");
                                        alert("I'm having trouble making this image-dropdown work..!");
                                    }}/>*/}
                                    {/*</div>*/}

                                    <Dropdown.Menu variant="dark" style={{width: "100%",}}>
                                        <Dropdown.Item href="profile">Edit Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>{alert("TO IMPLEMENT: Log out method.");}}>Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button className="p-small" style={{margin: 0, padding: "8px 4px", height: "66%",}}
                                        onClick={() => {
                                            this.setState({showSignUpInDialog: true});
                                        }}>Create Account/Sign In
                                </button>
                            </>)}
                            {(this.state.isSignedIn && <>
                                {/*<p>Log out??</p>*/}
                                <img src="/Images/Checkmark.webp" alt="Profile"
                                     className="profile-icon"
                                     style={{aspectRatio: "1 / 1", paddingRight: "4px",}}
                                     onClick={(e) => {
                                         this.setState({showProfileDropdown: true,});
                                     }}/>
                                <button className="p-small" style={{margin: 0, padding: "8px 4px", height: "66%",}}
                                        onClick={(e) => {
                                            console.log("Logging out...");
                                            this.logOut();
                                        }}>Log Out
                                </button>
                            </>)}
                        </div>}

                        {(this.state.showSignUpInDialog &&
                            <SignInUpDialog onSignUpMethod={this.props.signUpMethod}
                                            onCloseMethod={this.signInUpDialogCloseMethod}
                                            onSignInMethod={this.props.signInMethod}
                                            onSignInMethodSuccess={() => {this.setState({isSignedIn: true})}}/>)}
                    </div>
                </div>

                <div style={{
                    width: "100vw", position: "fixed",
                    display: "flex", justifyContent: "right",
                    zIndex: 10000,
                }}>
                    {
                        // https://javascript.plainenglish.io/create-a-sliding-react-navigation-bar-441168bca19
                        this.isMobile() &&
                        <FontAwesomeIcon onClick={this.toggleMenu}
                                         icon={this.state.showMenu ? faClose : faMenu}
                                         style={{padding: "8px", zIndex: 10000, color: "#fff", height: "2rem",}}/>
                    }
                </div>
            </header>
        );
    }
}

export default MyNavbar;

//  https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
//      Made to implement Icon Toggles for dropdowns (I.E, the profile dropdown icon)
//  https://stackoverflow.com/questions/71281998/unable-to-style-component-using-forwardref
//      To put this above the export line is problematic.
//const BlankToggle = React.forwardRef(({ children, onClick }, ref) => (
const BlankToggle = React.forwardRef(({ children, onClick, style, className }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={style}
        className={className}
    >
        {children}
    </a>
));