import React from "react";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {profilePic: this.props.profilePic};
    }

    setImage(file) {
        //pic.src = URL.createObjectURL(file);
        //document.getElementByID("pic").src = URL.createObjectURL(file);
        //this.props.profilePic = URL.createObjectURL(file);
        let profilePic = URL.createObjectURL(file);
        this.setState({profilePic:profilePic});
    }

    // TODO OPTIONAL: Reimplement this with a .css file?
    render() {
        let width = 250;  // -
        let height = 100;  // 100%
        /*let padding = 15;  // 15%
        let picSize = 64;  // 64%
        let borderSize = 70;  // 70%
        let padding = "15%";%
        let picSize = "64%";%
        let borderSize = "70%";*/
        let padding = 0.15 * height;  // 15%
        let picSize = 0.64 * height;  // 64%
        let borderSize = 0.7 * height;  // 70%
        let textWidth = width - borderSize - 3 * padding;  // 250 - borderSize - padding * 3

        let picBorderPos = padding;
        let picPos = picBorderPos + 3;

        return (
            <div style={{ width: width - 2 * padding, height: height - 2 * padding, position: 'relative',
                padding: "15px",
                backgroundColor: 'rgb(192, 192, 192)' }}>
                <img src='/Images/Profiles/prof_border.webp' alt=""
                    style = {{position: "absolute", top: 0, left: 0}}/>
                <div style={{ position: "relative",
                    display: "flex", justifyContent: "center", alignItems: "center",
                    width: borderSize, height: borderSize,}}>
                        <img src="/Images/Profiles/aa_border.webp" alt=""
                             style={{ position: 'relative', left: 0, top: 0,
                                 width: "inherit", height: "inherit",}}/>
                        <img id="pic" src={this.state.profilePic} alt=""
                             style={{ position: "absolute", top: "50%", left: "50%",
                                 transform: "translate(-50%, -50%)",
                                 width: picSize, height: picSize,}} />
                </div>
                <div style={{position: 'absolute', left: padding + borderSize + padding, top: padding,
                    width: textWidth,
                }}>
                    <p style={{
                        margin: 0,
                        fontSize: '16px', textAlign: 'left', }}>
                        {this.props.username}
                    </p>
                    <p style={{ top: 30,
                        margin: 0,
                        fontFamily: 'Courier New, monospace', fontSize: '11px', textAlign: 'left', }}>
                        {this.props.description}
                    </p>
                </div>
            </div>
            /*<div style={{ width: width - 2 * padding, height: height - 2 * padding, position: 'relative',
                //display: "flex", justifyContent: "center", alignItems: "center",
                padding: "15px",
                backgroundColor: 'rgb(192, 192, 192)' }}>
                {/*<img src='/Images/Profiles/prof_border.webp' alt="" />**}
                <div style={{
                    width: borderSize, height: borderSize,}}>
                    <img src="/Images/Profiles/aa_border.webp" alt=""
                         style={{ width: borderSize, height: borderSize,}} />
                    <div style={{ left: picPos, top: picPos, position: 'absolute',
                        width: picSize, height: picSize,
                        backgroundColor: 'rgb(128, 128, 128)' }}/>
                    <img id="pic" src={this.state.profilePic} alt=""
                         style={{ position: 'absolute', left: picPos, top: picPos,
                             width: picSize, height: picSize,}} />
                </div>
                <div style={{position: 'absolute', left: padding + borderSize + padding, top: padding,}}>
                    <p style={{
                        margin: 0,
                        fontSize: '20px', textAlign: 'left', }}>
                        {this.props.username}
                    </p>
                    <p style={{ top: 30,
                        margin: 0,
                        fontFamily: 'Courier New, monospace', fontSize: '12px', textAlign: 'left', }}>
                        {this.props.description}
                    </p>
                </div>
            </div>*/
            /*<div style={{ width: width - 2 * padding, height: height - 2 * padding, position: 'relative',
                display: "flex", justifyContent: "center", alignItems: "center",
                padding: "15px",
                backgroundColor: 'rgb(192, 192, 192)' }}>
                <img src='/Images/Profiles/prof_border.webp' alt="" />
                <img src="/Images/Profiles/aa_border.webp" alt=""
                     style={{ position: 'absolute', width: picSize + 3 * 2, height: picSize + 3 * 2, left: 15, top: 15 }} />
                <div style={{ width: picSize, height: picSize, left: 18, top: 18, position: 'absolute',
                    backgroundColor: 'rgb(128, 128, 128)' }}/>
                <img id="pic" src={this.state.profilePic} alt=""
                    style={{ position: 'absolute', width: 64, height: 64, left: 18, top: 18 }} />
                <p style={{ position: 'absolute', left: 105, top: 15, margin: 0, fontSize: '20px' }}>
                    {this.props.username}</p>
                <p style={{ position: 'absolute', left: 105, top: 45, margin: 0, fontFamily: 'Courier New, monospace', fontSize: '12px' }}>
                    {this.props.description}</p>
            </div>*/
            /*<div /*name="profile"** style={{ width: 250, height: 100, position: 'relative', backgroundColor: 'rgb(192, 192, 192)' }}>
                <img src='/Images/Profiles/prof_border.webp' alt="" />
                <img src="/Images/Profiles/aa_border.webp" alt=""
                     style={{ position: 'absolute', width: 70, height: 70, left: 15, top: 15 }} />
                <div style={{ width: 64, height: 64, left: 18, top: 18, position: 'absolute', backgroundColor: 'rgb(128, 128, 128)' }}/>
                <img id="pic" src={this.state.profilePic} alt=""
                    style={{ position: 'absolute', width: 64, height: 64, left: 18, top: 18 }} />
                <p style={{ position: 'absolute', left: 105, top: 15, margin: 0, fontSize: '20px' }}>
                    {this.props.username}</p>
                <p style={{ position: 'absolute', left: 105, top: 45, margin: 0, fontFamily: 'Courier New, monospace', fontSize: '12px' }}>
                    {this.props.description}</p>
            </div>*/
        );
    }
}

class ProfileList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {elementArray: []};
    }

    retrieveProfileListData() {
        // TODO: In the perfect project, this would have been implemented with a database.
        //       I will not do this, because I want to minimize costs.
        //       Though, I might fool around to add database experience to my portfolio?
        //      Update: Yes, I think I will.
        let profiles = [
            ["Blawnode", "Familiar.", "Blawnode Logo (MAX, coloured).webp"],
            ["Hahappy", "The first!", "haha.webp"],
            ["Angry Grounds", ">:(", "anrgy.webp"],
            ["New Angry", ">:)", "anorchy.webp"],
            ["Derp", "Brep.", "drep.webp"],
            /*["haha", "The OG Blawnode foshu!", "haha.webp"],
            ["Angry Faic", ">:(", "anrgy.webp"],
            ["Anorchy Faic", "Don't say it", "anorchy.webp"],
            ["Drip Drep", "HE'S GOT THE DRIP!", "drep.webp"],*/
        ];
        let profilePicPath = "/Images/Profiles/Profile Icons/";
        let profilesPrefixed = profiles.map(
            profile => {
                // All the items except the last. Add a prefix to the last item.
                let lastItemPrefixed = profilePicPath + profile.at(-1);
                return [...profile.slice(0, -1), lastItemPrefixed];
            }
        );
        return profilesPrefixed.map(profile => {
            return <Profile username={profile[0]}
                            description={profile[1]}
                            profilePic={profile[2]} />;
        });
    }

    // https://www.codegrepper.com/code-examples/javascript/how+to+populate+a+list+in+html+with+javascript
    componentDidMount() {
        let elementArray = this.retrieveProfileListData();
        this.setState({elementArray: elementArray});
    }

    render() {
        return (
            <div >
                {/*<div name="builtPremadeProfileList" style={{ width: 300, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                    <Profiles username="Blawnode" description="H a t." profilePic="/Images/haha.webp" />
                    <Profiles username="Anrgy Faic" description=">:(" profilePic="/Images/anrgy.webp" />
                    <Profiles username="Drip Drep" description="HE'S GOT THE DRIP!" profilePic="/Images/drep.webp" />
                </div>*/}
                <ul id="builtProfileList" style={{
                    listStyle: "none", paddingLeft: 0,
                    width: 550, height: this.state.elementArray.length * 110,
                    position: 'relative',
                    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'
                }}>
                    {this.state.elementArray.map((x, i) => (<li key={i}>{x}</li>))}
                </ul>
            </div >
        );
    }
}

export { Profile, ProfileList };