import React from "react";
import {Link} from "react-router-dom";
import {MePics} from "../MePics";
import Select from "react-select";

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

class HomePage extends React.Component {
    componentDidMount(){
        document.title = "Home - Ido Waisbart"
        this.OnClickStartFlag = this.OnClickStartFlag.bind(this);
        this.isMobile = this.isMobile.bind(this);
    }

    OnClickStartFlag(){
        console.log("(Unfunctional)");
    }

    // For explanation, see the same method at MePics.js.
    isMobile(){
        return window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
    }

    render() {
        let isMobile = this.isMobile();

        return (
            <div className="MyPage middle-inner-part-margin"
                 style={{height: "100%"}}>
                <article style={{margin: "0", height: "100%", minHeight: "inherit",}}>
                    {/*<div className='article-title-wrapper'>
                        <h1>
                            My Lil' Website
                        </h1>
                        <h5>
                            By: Ido Waisbart
                        </h5>
                    </div>*/}

                    {/*<Select>*/}
                    {/*    /!*defaultValue={selectImportanceOptionsTest[0]}*!/*/}
                    {/*    options={options}*/}
                    {/*    noOptionsMessage={() => 'wtf'}*/}
                    {/*</Select>*/}

                    {/*TODO: This exact div appears twice. Make a component out of it!*/}
                    <div
                        style={{height: "100%", minHeight: "inherit",
                        display: isMobile ? "flex" : "auto", flexDirection: isMobile ? "column" : "auto",
                        justifyContent: isMobile ? "space-evenly" : "auto",}}>
                        <MePics/>
                        <div className="article-content-wrapper large-font-inside"
                             style={{
                                 position: "relative",
                                 top: "auto",
                                 width: isMobile ? "100%" : "40vw", height: isMobile ? "auto" : "100%",
                                 minHeight: isMobile ? "auto" : "inherit",
                             }}>
                            <h2 style={{marginTop: "1vw"}}>I am
                                <i> </i><Link to="/about" className="tooltip-container" >Ido Waisbart
                                <span className="tooltip-text" style={{fontSize: "large"}}>About me</span>
                            </Link>, a creative.</h2>
                            <p>I develop <i>software</i> as a profession, like
                                <i> </i><Link to="/aboutwebsite" className="tooltip-container">React.js websites
                                    <span className="tooltip-text" style={{fontSize: "large"}}>Like this website!</span>
                                </Link><i> </i>
                                and the occasional <span className="tooltip-container">.NET desktop applications
                                    <span className="tooltip-text"
                                          style={{fontSize: "large"}}>C#, WPF and WinForms</span>
                                </span>,</p>
                            <p>And, I also made many <Link to="/about" className="tooltip-container pixel-font"
                                                                style={{fontSize: isMobile ? "5vw" : "2vw"}}>games
                                <span className="tooltip-text">About my games</span>
                            </Link>,
                                and <span style={{display: "inline-block"}}>game-related</span> creations.</p>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default HomePage;