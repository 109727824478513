import React from "react";
import logo from "../../logo.svg";
import UserData from "../Profiles/UserData";
import ItchArticle from "../ItchArticle";

class TestboxPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.HeLikedA = this.HeLikedA.bind(this);
        this.HeLikedB = this.HeLikedB.bind(this);
    }

    componentDidMount() {
        document.title = "TESTBOX - Ido Waisbart"

        let likedLetter = localStorage.getItem("LikedLetter");

        if (likedLetter === null) {
            likedLetter = 'A';
            localStorage.setItem("LikedLetter", 'A');
            //document.cookie = "likedLetterCookie=A";
        }
        this.setState({likedLetter: likedLetter, likedLetterCookie: likedLetter});
    }

    HeLikedA(){
        localStorage.setItem("LikedLetter", 'A');
        //document.cookie = "likedLetterCookie=A";
        this.setState({likedLetter: 'A', likedLetterCookie: 'A'});
    }

    HeLikedB(){
        localStorage.setItem("LikedLetter", 'B');
        //document.cookie = "likedLetterCookie=B";
        this.setState({likedLetter: 'B', likedLetterCookie: 'B'});
    }

    render() {
        return (
            <div className="middle-inner-part-margin" style={{backgroundImage: "url(/Images/NitromeBGPurple.png)", width: "100%",
                display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1>The most test box!</h1>

                <img src={logo} className="App-logo" alt="logo"/>
                <br/>
                <a className="App-link"
                   href="https://wompampsupport.azureedge.net/fetchimage?siteId=7575&v=2&jpgQuality=100&width=700&url=https%3A%2F%2Fi.kym-cdn.com%2Fentries%2Ficons%2Fmobile%2F000%2F032%2F205%2Fogre.jpg"
                   target="_blank"
                   rel="noopener noreferrer">
                    This is a mistake
                </a>
                <p style={{fontFamily: 'LastResort'}}>This is a mistake, too. Hoped for a <i>last resort</i>.</p>
                <p style={{fontFamily: 'fallbackFont'}}>Fallback?</p>
                <p style={{fontFamily: 'terminal-1'}}>Terminal!?</p>
                <p style={{fontFamily: 'terminal-1'}} dir='rtl'>בעברית??</p>
                <p style={{fontFamily: 'terminal-1'}} dir='rtl'>بالعربية??</p>
                <br/>
                <p>Check out my &lt;ItchArticle&gt;'s!</p>

                <ItchArticle blogTitle="THIS IS SICK, AGAIN" creator="That guy, Ido!" platform="idow.xyz"
                             nolink
                             textElements={[
                                 <p>This is an article I guess.</p>,
                                 <p>Here I write stuff, like 'stuff', and profanities.</p>,
                                 <p>Check this out:</p>,
                                 <p><b><i>F***.</i></b></p>,
                                 <p>Okay maybe I can't, but that's pretty bad@$$.</p>,
                                 <p>Here's some code:</p>,
                                 <p style={{textAlign: "left"}}><code>if youCanSeeThis == true:<br/>
                                     &emsp; DoNothingBecauseThisCodeIsntReal()<br/></code></p>]}
                />

                <br/>
                <p>Trial of the mask???</p>
                <iframe width="500" height="281" src="//www.youtube.com/embed/OB2hPp_rPc4" frameBorder="0"
                        allowFullScreen="" />
                <br/>

                <ItchArticle blogTitle="Marco!" creator="BN" platform="itch.io"
                             link="https://blawnode.itch.io/adrift/devlog/420562/marco"
                             font="terminal-1"
                             textElements={[<p>Is anyone able to see this post, except for the team?</p>]}
                />

                <ItchArticle blogTitle="August Session Blog - Team Member Departure" creator="BN" platform="itch.io"
                             link="https://blawnode.itch.io/adrift/devlog/421406/august-session-blog-team-member-departure"
                             font="terminal-1"
                             textElements={[
                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODUucG5n/original/sxv%2BrZ.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     Ahh, continued jam games.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     Once that happens, as the team gives the thumbs up to continue working?
                                     The feeling is golden.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     Our team of 6 members has since gone and done many things:
                                     Start from Unreal, transfer the game to Unity, design and dream of what could be
                                     made,<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     And so far, 5 phases were conducted, and 5 game versions were made!</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     All stemmed from a very, <b> very </b> casual and small game jam.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0OTQucG5n/original/UXEj%2FF.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     Since, the team has fallen apart a bit. 2 members left to continue their journey,
                                     and another did too, slowly.
                                     And now, it's me (Blawnode), redsti and NoStereo.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0OTAucG5n/original/oAfR7p.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     It's still been fun since, but it's obviously not the same.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzUucG5n/original/GCyCMN.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     The remaining members are trying our best to deliver a game to be remembered.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODYucG5n/original/zGlzd1.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     <b>... is there anything to learn from the experience? </b>
                                     I believe so.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzkucG5n/original/HsEOyk.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     The root cause of the team's rupture was not that the project got stale too fast,
                                     or
                                     that it was not promising enough.
                                     It was not fights within the team, nor doubts about the product.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     In fact... <b> I believe it went perfectly fine.</b></p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     I believe that the project's state is just great. It's all just... expectable.
                                     You shouldn't go into a months-long project, reviving a super-casual team, and
                                     expect it to be whole till the very end.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0ODUucG5n/original/sxv%2BrZ.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     Of course not! Sometimes, life causes or forces changes in our journeys'
                                     trajectories.
                                     And it's just fine.
                                     Especially within the context of this casual project.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"
                                         className="unselectable"/>&nbsp;
                                     My point? Life will interfere with your teams' plans, and it is something that
                                     needs
                                     to be acknowledged, and accepted.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                          alt="Blawnode Expression"
                                          className="unselectable"/>&nbsp;
                                     If you do, the adventure will be much more tolerable. I insist on that.
                                     Remain positive, no matter the departures of the team members.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"
                                        className="unselectable"/>&nbsp;
                                     They sure would prefer that you would persist. :)</p>]}
                />

                <ItchArticle vlogPath="Vlogs/blog_test.json"/>
                <ItchArticle vlogPath="Vlogs/BNBlog - Adrift August.json"/>
                <ItchArticle vlogPath="Vlogs/BNBlog - Adrift Hiatus Break.json"/>

                <UserData/>

                {/*<Comments/>*/  /*A comments section.*/}
                <div style={{margin: "10vh 0"}}>
                    <form>
                        <input type="textbox" placeholder="Comment..."/>
                    </form>
                </div>

                {/*<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6984609427391721472"
                            height="429" width="504" frameBorder="0" allowFullScreen="" title="Embedded post"/>*/}
                {/*<iframe style={{border: 0, width: "200px", height: "314px",}}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2935833691/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
                        seamless>
                    <a href="https://blawnode.bandcamp.com/track/a-mistake-contest-version">
                        A Mistake (Contest Version) by Blawnode
                    </a>
                </iframe>*/}

                {/*Doesn't seem to work*/}
                <audio src="https://github.com/Ido-Waisbart/TestSound/blob/main/Untitled.wav"
                       style={{maxWidth: "100%", width: "30vw"}} controls preload="auto" /*autoPlay*/>
                    <a href="https://github.com/Ido-Waisbart/TestSound/blob/main/Untitled.wav">
                        HAHAHA
                    </a>
                </audio>

                {/*Probably only worked on local*/}
                <audio src="wot.wav"
                       style={{maxWidth: "100%", width: "20vw"}} controls preload="auto" /*autoPlay*/>
                    <a href="wot.wav">
                        HAHAHA
                    </a>
                </audio>

                <iframe title="Adrift - Itch Page" frameBorder="0"
                        src="https://itch.io/embed/1538958?linkback=true&amp;border_width=0&amp;bg_color=14182e&amp;fg_color=dfe0e8&amp;link_color=f0b541&amp;border_color=41455b"
                        width="550" height="165">
                    <a href="https://blawnode.itch.io/adrift">Adrift Program by Blawnode, Redsti, NoStereo</a>
                </iframe>

                <div>
                    <button onClick={this.HeLikedA}>I LIKE 'A'</button>
                    <button onClick={this.HeLikedB}>I LIKE 'B'</button>
                </div>

                <p>{this.state.likedLetter}</p>
                <p>{this.state.likedLetterCookie}</p>
            </div>
        );
    }
}

export default TestboxPage;