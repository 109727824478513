import React from "react";
import ReactDOM from "react-dom";
import { Profile } from '../Profiles/Profile'

class EditProfilePage extends React.Component {
    constructor(props) {
        super(props);
        this.newProfile = null;

        this.setNewProfile = element => {
            this.newProfile = element;
        };
    }

    componentDidMount() {
        // mustn't store react components in state
        // Instead, use: https://reactjs.org/docs/refs-and-the-dom.html
        /*this.state.profile = */ ReactDOM.render(<Profile ref={this.setNewProfile} username="Blawnode" description="H a t." profilePic="/Images/haha.webp" />, document.getElementById("profileContainer"));
        let myFile = document.getElementById("myFile");
        myFile.onchange = evt => {
            const [file] = myFile.files
            if (file) {
                this.newProfile.setImage(file);
            }
        }
    }

    render() {
        const handleFileClick = (event) => {
            document.getElementById('myFile').click();
        }
        const handleDescriptionClick = (event) => {
            // TODO
        }
        return (
            <div className="middle-inner-part-margin">
                <div id="profileContainer" style={{ display: "flex", justifyContent: "center" }}></div><br />
                <button style={{ display: "block", width: "180px", height: "30px" }} onClick={handleDescriptionClick}>Edit Description</button>
                <input accept="image/*" type="file" id="myFile" name="filename" style={{ display: "none" }}></input>
                <button style={{ display: "block", width: "180px", height: "30px" }} onClick={handleFileClick}>Upload profile picture</button>
            </div>
        );
    }
}

export default EditProfilePage;