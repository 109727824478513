import './App.scss';
import './GlobalStyles.scss';
import {
    Routes,
    Route,
} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {AllProfilesPage, HomePage, ContactPage, ToBeAddedPage,
    AboutPage, AboutWebsitePage,
    HirePage, PortfolioPage, ServicesPage,
    AdriftPage, AebfPage,
    TestboxPage, DemoPage,
    MyNavbar,} from './index';
import MailchimpFormContainer from "./Components/MailchimpFormContainer";  // https://stackoverflow.com/questions/46984955/how-to-import-all-components-in-react

//import logo from "./logo.svg";
//import "@aws-amplify/ui-react/styles.css";
//import withAuthenticator from "@aws-amplify/ui-react";

import { Auth } from 'aws-amplify';

import {validateEmail, validatePassword, validateSignUpParameters, validateUsername,} from "./ValidationMethods.ts";
import UploadImageToS3UsingNativeSdk from "./UploadImageToS3UsingNativeSDK";

// TODO: Doesn't work as intended, it seems.
// To ensure unique mail: https://github.com/aws-amplify/amplify-js/issues/1067#issuecomment-697298661
async function ensureUniqueEmail(username){
    const code = '000000'  // An obviously wrong code. That's the trick!
    Auth.confirmSignUp(username, code, {
        // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
        forceAliasCreation: false
    }).then(data => console.log(data))
        .catch( err => {
            switch ( err.code ) {
                case 'AliasExistsException':
                    // Email alias already exists
                    return false;
                default:
                    return true;
            }
        } )
}

// https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up
/* Create a new user in the Amazon Cognito UserPool by passing the new user's email address,
*   password, and other attributes to Auth.signUp.
* The Auth.signUp promise returns a data object of type ISignUpResult with a CognitoUser.
* CognitoUser contains a userSub which is a unique identifier of the authenticated user;
*   the userSub is not the same as the username.
*/
async function signUp(username, password, email,) {
    let [isValid, validationErrorMessage] = validateSignUpParameters(username, password, email);
    if(!isValid) {
        console.log("Failed to sign up: " + validationErrorMessage);
        return;
    }
    try {
        const {user} = await Auth.signUp({
            username,
            password,
            attributes:  // Optional
                {
                    email,
                },
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            }
        });
        if(!(await ensureUniqueEmail(username))){
            console.log("Email is not unique! Abort!");
            return [false, "Failed to sign up: The email was already signed up before."];
        }
        console.log("Signed up successfully!");
        console.log(user);  // TODO: Need I do anything with this?
        return [true, user.toString()];
    } catch (error) {
        console.log('Failed to sign up: ' + error.toString());
        return [false, error.toString()];
    }
}

// TODO: Doesn't work with email.
async function signIn(name, password,) {
    let [isValid, validationErrorMessage] = validateUsername(name);
    if(!isValid) {
        let errorMessage = validationErrorMessage;
        [isValid, validationErrorMessage] = validateEmail(name);
        if(!isValid) {
            console.log('Invalid name: ' + validationErrorMessage);
            return [false, validationErrorMessage, null];
        }
    }

    [isValid, validationErrorMessage] = validatePassword(password);
    if(!isValid) {
        console.log('Invalid password: ' + validationErrorMessage);
        return [false, validationErrorMessage, null];
    }

    try {
        const user = await Auth.signIn(name, password);
        return [true, "Successfully signed in.", user];
    } catch (error) {
        // TODO: Doesn't work with email.
        return [false, error, null];
    }
}

// TODO: This method is currently unused.
async function deleteUser() {
    try {
        const result = await Auth.deleteUser();
        console.log(result);
    } catch (error) {
        console.log('Error deleting user: ' + error.toString());
    }
}

// user.attributes has: email, email_verified, sub
function BNApp({ signOut, user }) {
    const [users, setUsers] = useState(false);
    /*useEffect(() => {
        getUsers();
    }, []);*/  // I don't want to use it when the website first loads, for now.

    function getUsers() {
        fetch('http://localhost:3001')
            .then(response => {
                return response.text();
            })
            .then(data => {
                setUsers(data);
            })
            .catch(error => {
                //console.error('Error:', error);
                //console.log("server is down!!")
            });
    }

    // Requirements:
    //  -   8 to 32 characters.
    //  -   Must start with an alphabet.
    //  -   Only alphabet and/or numbers.
    function isUsernameValid(){
        fetch('http://localhost:3001')
            .then(response => {
                console.log("ayyyyyyye lmao");
                console.log(response.text());
                console.log(response.toString());
                console.log(response);
            });
    }

    function createUser() {
        let username = prompt('Enter username');
        isUsernameValid();
        let nickname = prompt('Enter nickname');
        let password = prompt('Enter password');
        let email = prompt('Enter email');
        fetch('http://localhost:3001/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, nickname, password, email}),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                alert(data);
                getUsers();
            })
            .catch((error) => {
                //console.error('Error:', error);
                //console.log("server is down!!")
            });
    }

    function deleteUser() {
        let id = prompt('Enter merchant id');
        fetch(`http://localhost:3001/users/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                alert(data);
                getUsers();
            })
            .catch((error) => {
                //console.error('Error:', error);
                //console.log("server is down!!")
            });
    }

    return (
        <div className="App">
            <MyNavbar signUpMethod={signUp} signInMethod={signIn}/>
            <div className='middle-vertical-part'>
                <div className="left-space App-side">
                    {/*<p style={{fontSize: "8px", width: "inherit", overflowWrap: "anywhere"}}>
                        {users ? users : 'There is no user data available'}
                    </p>
                    <br />
                    <button onClick={createUser}>Add user</button>
                    <br />
                    <button onClick={deleteUser}>Delete user</button>*/}
                </div>
                <div className="middle-inner-part App-body">
                    <Routes>
                        <Route exact path="/" element={<HomePage/>}/>
                        {/*<Route exact path="/portfolio" element={<PortfolioPage/>}/>*/}
                        {/*<Route exact path="/games" element={<h1>Games</h1>}/>*/}
                        <Route exact path="/aboutwebsite" element={<AboutWebsitePage/>}/>
                        <Route exact path="/about" element={<AboutPage/>}/>
                        <Route exact path="/hireme" element={<HirePage/>}/>
                        <Route exact path="/services" element={<ServicesPage/>}/>
                        {/*<Route exact path="/blogs" element={<h1>Blogs</h1>}/>*/}
                        {/*<Route exact path="/community" element={<AllProfilesPage/>}/>*/}
                        {/*<Route exact path="/editprofile" element={<h1>To be added: Edit Profile Page</h1>}/>*/}
                        <Route exact path="/contact" element={<ContactPage/>}/>
                        <Route exact path="/tobeadded" element={<ToBeAddedPage/>}/>
                        <Route exact path="/testbox" element={<TestboxPage/>}/>
                        <Route exact path="/demo" element={<DemoPage/>}/>
                        <Route exact path="/games/adrift" element={<AdriftPage/>}/>
                        <Route exact path="/games/aebf" element={<AebfPage/>}/>
                        <Route path="*" element={<h1 style={{color: "#f00"}}>MISSING PAGE</h1>}/>
                    </Routes>
                </div>
                <div className="right-space App-side" >
                    {/*<UploadImageToS3UsingNativeSdk/>*/}
                </div>
            </div>
            <footer>
                {/*TODO: Add basic contact details here, too.*/}
                {/*TODO: Let this be shown if the page is small enough. (Like the Contact Page)*/}
                <MailchimpFormContainer/>
                <div style={{height: "2vh"}}/>
                {/*5vw*/}
                <p style={{fontSize: "12px", /*marginBlockStart: 0*/}}>Made by Ido Waisbart.</p>
            </footer>
        </div>
    );
}

export default BNApp;
