import React from "react";
import MyPath from "../MyPath";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

class PortfolioPage extends React.Component {
    componentDidMount(){
        document.title = "Portfolio - Ido Waisbart"
    }

    render() {
        return (
            <div className="middle-inner-part-margin">
                <h1>Portfolio</h1>
                <p>This page is due to be expanded.</p>
            </div>
        );
    }
}

export default PortfolioPage;