// TODO: CONSIDER: Is there too much text for the summary of each node?
//      I think that I should summarize more concisely, AND make the font size bigger.

import React from "react";
//import parse from "html-react-parser";
import {isElement} from "react-dom/test-utils";
//import PropTypes from 'prop-types';
//import { renderToString } from 'react-dom/server'  // https://stackoverflow.com/questions/37079847/is-it-ok-to-use-reactdomserver-rendertostring-in-the-browser-in-areas-where-reac
//import {styles as appStyle} from "../App.scss"  // https://stackoverflow.com/questions/52900207/how-to-use-scss-variables-into-my-react-components
                                             // https://webpack.js.org/loaders/sass-loader/


let backdropHolderStyleLeft = {position: "absolute", left: "0", top: "0",
    /*width: "50vh", height: "50vh",*/ /*margin: "auto",*/};
let backdropStyleLeft = {transform: "rotate(15deg)",};
let textStyleLeft = {position: "absolute", /*left: "-6vw", top: "10px",*/
    /*width: "80vh", height: "50vh",*/};
let pathStyleLeft = {
    //position: "absolute", left: "350px", top: "250px",
    position: "absolute", left: "35%", top: "45vh",
    //width: "240px", height: "260px",
    width: "calc(30% - 40px)", height: "60%",  // 40px = Size of the square/path stone.
};

let backdropHolderStyleRight = {position: "absolute", right: "0", top: "0px",
    /*width: "50vh", height: "50vh",*/};
let backdropStyleRight = {transform: "rotate(-15deg)",};
let textStyleRight = {position: "absolute", /*right: "-6vw", top: "10px",*/
    /*width: "80vh", height: "50vh",*/};
let pathStyleRight = {
//            position: "absolute", left: "350px", top: "250px",
    position: "absolute", left: "35%", top: "45vh",
//            width: "240px", height: "260px",
    width: "calc(30% - 40px)", height: "60%",  // 40px = Size of the square/path stone.
};  // TODO


class MyPathNode extends React.Component {
    constructor(props) {
        super(props);
        // props:  (string)             backdropClassName
        //             className of the backdrop image.
        //         (boolean)            isRight
        //             will the component be on the right or the left of the path?
        //         (string/DOM Element) textElement
        //             Either a <div> with <p>'s, or a multilined string. ('\n''s?)

        /* states: (string)     backdropClassName       (see props)
        *          (boolean)    isRight                   (see props)
        *          (object)     backdropStyle
        *          (object)     textStyle
        *          (object)     pathStyle
        * */

        let backdropClassName = this.props.backdropClassName;

        /*let backdropHolderStyle = backdropHolderStyleLeft;
        let backdropStyle = backdropStyleLeft;
        let textStyle = textStyleLeft;
        let pathStyle = pathStyleLeft;*/

        let textElement = this.props.textElement;

        /*if(this.props.isRight)
        {
            backdropHolderStyle = backdropHolderStyleRight;
            backdropStyle = backdropStyleRight;
            textStyle = textStyleRight;
            pathStyle = pathStyleRight;
        }*/

        let [backdropHolderStyle, backdropStyle, textStyle, pathStyle] = this.props.isRight ?
            [backdropHolderStyleRight, backdropStyleRight, textStyleRight, pathStyleRight]
            : [backdropHolderStyleLeft, backdropStyleLeft, textStyleLeft, pathStyleLeft];

        if (!(this.GetAllowedBackdropClassNames().includes(backdropClassName))) {
            backdropClassName = "backdrop-missing"
        }

        if (typeof (this.props.textElement) == "string") {
            // It's an string
            // Much faster alternative and writing of the path nodes.
            // TODO OPTIONAL: Support Discord articulation. If I wanted to bolden "success", I'd write "**success**"
            //       In addition: automatically articulate the tile/time/etc

            let lines = this.props.textElement.split("\\n\r ");  // No idea why's the space there unless I write this.

            textElement = <div className="backdropText">
                {lines.map((line, i) => {
                    return (<p key={i}>{line}</p>);
                })}
            </div>;
        } else if (isElement(this.props.textElement)) {
            // It's an element
            //console.log("elemnet");
            //console.log(renderToString(this.props.textElement));
        } else {
            // wtf is that
            console.log("wtf");
        }
        /*if (/matchme/.test(this.props.textElement.type)) {
            console.log('This child is <MyComponent />');
        }
        else{
            // It's an element
        }*/

        //  ASSUMPTION: The first element is of either of these forms:
        //      <p><u></u></p>
        //      <a><p><u></u></p></a>
        let firstParagraphAKATitle = textElement.props.children[0];
        let titleText;
        if(firstParagraphAKATitle.type == "a")
        {
            titleText = firstParagraphAKATitle.props.children.props.children.props.children;  // No idea why this works. Child of element may be either text, another element, or a combo, ig.
            //titleText = firstParagraphAKATitle.props.children.props.children;  // No idea why this works.
        }
        else if(firstParagraphAKATitle.type == "p"){
            titleText = firstParagraphAKATitle.props.children.props.children;  // No idea why this works.
        }
        else{
            console.error("UNEXPECTED FIRST PARAGRAPH AKA TITLE TYPE: " + firstParagraphAKATitle.type + ".\nThe element's child is: " + firstParagraphAKATitle.props.children.type);
            titleText = "UNEXPECTED FIRST PARAGRAPH AKA TITLE TYPE";
        }

        let isString = value => typeof value === 'string' || value instanceof String;
        let decompose = (complexElement) => {
            let decomposedText;
            if(Array.isArray(complexElement)){
                // 💡 <b>_</b> SPACE => 💡 _ SPACE
                let decomposedComplexElementParts = complexElement.map(element => decompose(element));
                decomposedText = decomposedComplexElementParts.reduce((result, element) => result + element.toString(), "");
            }
            else if(isString (complexElement)){
                decomposedText = complexElement.toString();
            }
            else{
                // Element? WARNING: It may have elements inside, in a more complex scenario!
                decomposedText = complexElement.props.children.toString();
            }

            return decomposedText;
        };
        //console.log(decompose(titleText));

        if(Array.isArray(titleText)){
            //console.log("HOLY KIT! AN ARRAY! D E C O M P O S E!")
            titleText = decompose(titleText);
        }

        this.state = {
            backdropClassName: backdropClassName,
            backdropHolderStyle: backdropHolderStyle,
            backdropStyle: backdropStyle,
            textStyle: textStyle,
            textElement: textElement,
            pathStyle: pathStyle,
            isRight: this.props.isRight,
            titleText: titleText,
        };

        this.props.InsertTitleToParent(titleText);
        let [TellParentIfVisible, i] = this.props.TellParentIfVisible;
        TellParentIfVisible(i, this.IsNodeImportantEnough(), titleText);
        this.GenerateStonePaths = this.GenerateStonePaths.bind(this);
        this.IsNodeImportantEnough = this.IsNodeImportantEnough.bind(this);
    }

    /*componentDidMount() {
        if(this.props.isRight) {
            let backdropHolderStyleRight = {
                position: "absolute", right: "0", top: "0px",
                width: "50vh", height: "50vh",
            };
            let backdropStyleRight = {transform: "rotate(-15deg)",};
            let textStyleRight = {
                position: "absolute", right: "-6vw", top: "10px",
                width: "80vh", height: "50vh",
            };
            let pathStyleRight = {
//            position: "absolute", left: "350px", top: "250px",
                position: "absolute", left: "30%", top: "45vh",
//            width: "240px", height: "260px",
                width: "calc(40% - 40px)", height: "60%",  // 40px = Size of the square/path stone.
            }

            this.setState({backdropHolderStyle: backdropHolderStyleRight,
                backdropStyle: backdropStyleRight,
                textStyle: textStyleRight,
                pathStyle: pathStyleRight,});
        }
    }*/

    // TODO: Sometimes, this gets caught in an infinite loop! (Caught on localhost)
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(prevProps !== this.props)
        {
            // The PROPS have changed!
            let [backdropHolderStyle, backdropStyle, textStyle, pathStyle] = this.props.isRight ?
                [backdropHolderStyleRight, backdropStyleRight, textStyleRight, pathStyleRight]
                : [backdropHolderStyleLeft, backdropStyleLeft, textStyleLeft, pathStyleLeft];
            this.setState({
                backdropHolderStyle: backdropHolderStyle,
                backdropStyle: backdropStyle,
                textStyle: textStyle,
                pathStyle: pathStyle,
                isRight: this.props.isRight,
//                isVisible: this.props.isVisible,
            });
            let [TellParentIfVisible, i] = this.props.TellParentIfVisible;
            TellParentIfVisible(i, this.IsNodeImportantEnough(), this.state.titleText);
        }
    }

    GetAllowedBackdropClassNames(){
        return [
            "backdrop-generic",
            "backdrop-triple-triangle",
            "backdrop-adrift",
            "backdrop-freecodecamp",
            "backdrop-website",
        ];
    }

    GenerateStonePaths(){
        //let tops = ["0", "40px", "110px", "180px", "220px"];
        let tops = ["0", "16vh", "36vh", "56vh", "72vh"];
        //let lefts = ["0", "60px", "100px", "140px", "200px"];  // can be auto generated with a single number, 200. Same for tops, with 220.
        //let lefts = ["0", "10vw", "15vw", "20vw", "30vw"];  // can be auto generated with a single number, 200. Same for tops, with 220.
        let lefts = ["0%", "30%", "50%", "70%", "100%"];  // can be auto generated with a single number, 200. Same for tops, with 220.
        if(this.props.isRight)
            return <div className="my-path-line" style={this.state.pathStyle}>
                <div className="my-path-stone" style={{left: lefts[4], top: tops[0]}}/>
                <div className="my-path-stone" style={{left: lefts[3], top: tops[1]}}/>
                <div className="my-path-stone" style={{left: lefts[2], top: tops[2]}}/>
                <div className="my-path-stone" style={{left: lefts[1], top: tops[3]}}/>
                <div className="my-path-stone" style={{left: lefts[0], top: tops[4]}}/>
            </div>;
        else
            return <div className="my-path-line" style={this.state.pathStyle}>
                <div className="my-path-stone" style={{left: lefts[0], top: tops[0]}}/>
                <div className="my-path-stone" style={{left: lefts[1], top: tops[1]}}/>
                <div className="my-path-stone" style={{left: lefts[2], top: tops[2]}}/>
                <div className="my-path-stone" style={{left: lefts[3], top: tops[3]}}/>
                <div className="my-path-stone" style={{left: lefts[4], top: tops[4]}}/>
            </div>;
    }

    // (selectedImportance > someNodeImportance) means the node should be hidden.
    IsNodeImportantEnough = () => this.props.selectedImportance <= this.props.importanceType;

    render() {
        return (
            (this.IsNodeImportantEnough() /*&& this.state.isVisible*/) && <>
                {/* ↓ A skip marker. ↓
                    With it, you can go to idow.xyz/about#Title to go scroll to a specific node.*/}
                <div id={this.state.titleText} style={{marginTop: "25vh", marginBottom: "25vh",height: "1px"}}/>
                <div style={{
                    /*height: "50vh", */position: "relative",
                    marginTop: "25vh", marginBottom: "25vh",
                }} className="backdrop-size-expand-width">
                    <div className="backdrop-size" style={{...this.state.backdropHolderStyle, display: "flex", justifyContent: "center",}}>
                        <div style={this.state.backdropHolderStyle} className="backdrop-size">
                            <div className={this.state.backdropClassName}
                                 style={this.state.isRight ?
                                     {transform: "rotate(-15deg)",} :
                                     {transform: "rotate(15deg)",}}/>
                        </div>
                        <div style={this.state.textStyle} className="backdrop-text">
                            {this.state.textElement  /*https://stackoverflow.com/questions/71185391/unable-to-resolve-dependency-for-installing-html-parser*/}
                        </div>
                    </div>

                    {this.props.last ? null : this.GenerateStonePaths()}

                    {/*<div style={(this.props.isRight) ?
                    {width: "50vh", marginRight: "auto", visibility: "visible",}
                    : {width: "50vh", marginLeft: "auto", visibility: "hidden",}}
                >
                    <h1>About</h1>
                    <p>?</p>
                    <p>?</p>
                </div>*/}
                </div>
            </>
        );
    }
}

MyPathNode.defaultProps = {
    backdropClassName: "backdrop-generic",
    isRight: false,
    textElement: <div><p>Error Message</p><p>This path node's text element is missing.</p></div>,
}

// https://reactjs.org/docs/typechecking-with-proptypes.html
/*MyPathNode.propTypes = {
    optionalUnion: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
    ]),
}*/

export default MyPathNode;