import React from "react";
import MyPath from "../MyPath";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

import websiteWipStatus from 'Data and JSONs/WebsiteWipStatus.json';

class ToBeAddedPage extends React.Component {
    componentDidMount() {
        document.title = "To Be Added - Ido Waisbart"
    }

    render() {
        // https://forum.freecodecamp.org/t/newline-in-react-string-solved/68484
        let properlyNewLinedWipStatusLines = websiteWipStatus.description.split("\n");
        let properlyNewLinedWipStatus = properlyNewLinedWipStatusLines.map(line => <p>{line}</p>);

        return (
            <div className="middle-inner-part-margin">
                <h1>To Be Added</h1>
                <div style={{textAlign: "left", width: "70%", margin: "auto"}}>
                    <hr/>
                    <div style={{fontSize: "24px", textAlign: "center", listStyle: "none"}}>
                        (<u>Current Priority</u>)
                        <i> </i><b>{websiteWipStatus.itemInProgress}</b><br/>
                        {properlyNewLinedWipStatus}
                    </div>
                    <hr/>

                    <br/>

                    <ul>
                        <li><b>Databases</b> -
                            I will integrate a <u>PostgreSQL</u> user list for a mailing list, or the community page,
                            and a <u>MongoDB</u> database.
                        </li>
                        <li><b>File Support</b> -
                            Upload, view and download files from the website's server, like my résumés, or images.
                        </li>
                    </ul>

                    <br/>
                    <h5 style={{textAlign: "center"}}><u>Latest Changes</u></h5>
                    {/*<hr style={{width: "35%"}}/>*/}
                    <br/>

                    {/*TODO: Add additional text and links for the MyPath nodes.*/}
                    {/*TODO: Add portfolio page, in which my projects are listed tidily.*/}
                    {/*TODO: Add community page, in which I will add a functional user account database,
                        and the option to create, and modify accounts..*/}
                    <ul>
                        <li><b>Mailing List - 05/02/2023</b> -
                            I added a mailing list
                        </li>
                        <li><b>My Path - 01/2023</b> -
                            In the About Me page, I've constructed a path of my life's events, where you can jump to a
                            specific event, and filter by "importance"
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ToBeAddedPage;