import React from "react";

import {validateUsername, validatePassword, validateEmail,} from "../../ValidationMethods.ts";

class UserData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            usersPrettified: "",
            currentUser: null,
            randomUser: null,
            errorMessage: "",
        }
        this.refreshUsers();
        this.generateRandomUser();

        this.createUser = this.createUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.refreshUser = this.refreshUser.bind(this);
    }

    /*getUsers() {
        fetch('http://localhost:3001')
            .then(response => {
                return response.text();
            })
            .then(data => {
                return data;
            });
    }*/

    prettifyUserRecords(data){
        let usersSetsString = data.toString();
        usersSetsString = usersSetsString.substring(1, usersSetsString.length - 1);
        let usersPrettified = "";
        for(let i = 0; i < usersSetsString.length; i++) {
            if(usersSetsString[i] === '{' && i !== 0){
                usersPrettified += "\n\n";
            }
            else if(usersSetsString[i] === ','){
                usersPrettified += "\n";
                continue;
            }
            usersPrettified += usersSetsString[i];
        }
        return <p style={{fontSize: "1vw"}}>{usersPrettified}</p>;
    }

    refreshUsers() {
        fetch('http://localhost:3001')
            .then(response => {
                return response.text();
            })
            .then(data => {
                this.setState({users: data, usersPrettified: this.prettifyUserRecords(data)});
            });
    }

    refreshUser() {
        let username = prompt('Enter username');
        if (!this.validateUsername(username)) {
            // Maybe don't need this kind of validation.
            // Might need a different kind? To prevent js hijacking? (Same for <password>)
            console.log("User fetch/sign-in failed: Invalid username.");
            return;
        }
        let password = prompt('Enter password');
        fetch('http://localhost:3001/user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username, password}),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                alert(JSON.stringify(data));
                this.setState({currentUser: data});
                // Set the state ONLY IF I got a 200 message, or some "okay" message.
            });
    }

    // Assumption: The username is valid.
    usernameExists(username){
        return fetch('http://localhost:3001/userexists', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username}),
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                let dataRow = JSON.parse(data)[0];
                console.log(dataRow.exists);
                return dataRow.exists;
            });
    }

    // createUser() handles invalid and duplicate usernames.
    // The SQL server not will handle duplicate usernames.
    createUser() {
        let username = prompt('Enter username');

        let returned, errorMessage;

        ([returned, errorMessage] = this.validateUsername(username));
        if(!returned) {
            errorMessage = "User creation failed: Invalid username.\n" + errorMessage;
            console.log(errorMessage);
            this.setState({errorMessage: errorMessage});
            return;
        }

        let promiseUsernameExists = this.usernameExists(username);
        promiseUsernameExists.then((returned) => {
            if(returned){
                errorMessage = "User creation failed: Taken username.";
                console.log(errorMessage);
                this.setState({errorMessage: errorMessage});
                return;
            }

            // TODO: Validate nickname, password and email.
            let nickname = prompt('Enter nickname');
            let password = prompt('Enter password');
            // Encrypt with crypto-js!
            let email = prompt('Enter email');
            fetch('http://localhost:3001/createuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({username, nickname, password, email}),
            })
                .then(response => {
                    return response.text();
                })
                .then(data => {
                    alert(JSON.stringify(data));
                    this.refreshUsers();
                });
        });
    }

    deleteUser() {
        let id = prompt('Enter user id');
        fetch(`http://localhost:3001/users/${id}`, {
            method: 'DELETE',
        })
            .then(response => {
                return response.text();
            })
            .then(data => {
                alert(data);
                this.refreshUsers();
            });
    }

    generateRandomUser(){
        fetch('http://localhost:3001/randomuser')
            .then(response => {
                return response.text();
            })
            .then(data => {
                this.setState({randomUser: data});
            });
    }

    render() {
        return (<div>
            <div style={{width: "inherit", overflowWrap: "anywhere", letterSpacing: "0", whiteSpace: "break-spaces"}}>
                {this.state.users.length !== 0 ? this.state.usersPrettified : 'User list data is unavailable'}
            </div>
            <br/>
            <button onClick={this.createUser}>Add user</button>
            <br/>
            <button onClick={this.deleteUser}>Delete user</button>
            <br/>
            <button onClick={this.refreshUser}>Get user/Sign In</button>
            <br/>
            <p style={{fontSize: "8px", width: "inherit", overflowWrap: "anywhere", letterSpacing: "0"}}>
                {this.state.currentUser ? this.state.currentUser : null/*'Current user data is unavailable'*/}
            </p>
            <br/>
            <p style={{fontSize: "8px", color: "#f00", width: "inherit", overflowWrap: "anywhere", letterSpacing: "0"}}>
                {this.state.errorMessage}
            </p>
            <br/>
            <p style={{fontSize: "16px", color: "#0f0", width: "inherit", overflowWrap: "anywhere", letterSpacing: "0"}}>
                Random user:
            </p>
            <p style={{fontSize: "12px", color: "#0f0", width: "inherit", overflowWrap: "anywhere", letterSpacing: "0"}}>
                {this.state.randomUser}
            </p>
        </div>);
    }
}

export default UserData;