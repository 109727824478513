// Many of the methods were taken from: https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-up

import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX as faClose,} from "@fortawesome/free-solid-svg-icons";
import {validateUsername, validatePassword, validateEmail,} from "../ValidationMethods.ts";
import {Auth} from "aws-amplify";

// https://javascript.plainenglish.io/create-a-sliding-react-navigation-bar-441168bca19
const CloseButton = (props) =>
    <FontAwesomeIcon onClick={props.onClick}
                     icon={faClose} size="2x"
                     style={{
                         padding: "8px",
                         zIndex: 10000,
                         color: "#666",
                         margin: "0 0 auto auto",
                     }}/>;

const DirectVerificationCodeForm = (props) =>
    <form>
        <label className="p-small" htmlFor="resendVerificationCode">Resend verification code:
            <input id="nameResendCode" placeholder="Username/User ID"/>
            <input id="resendVerificationCode" value="Resend" type="submit"
                   onClick={(e) => {
                       props.onBtnResendVerificationCode(e, "nameResendCode");
                   }}/>
        </label>
        {
            props.verifiedSignUp ?
                <div><p className="p-small" style={{color: "#0b0"}}>Verified user successfully!</p></div>
                :
                <label className="p-small" htmlFor="submitVerificationCode">Submit verification code:
                    <input id="nameSubmitCode" placeholder="Username/User ID"/>
                    <input id="codeSubmitCode" type="number" placeholder="######"
                           style={{width: "7vw"}}/>
                    <input id="submitVerificationCode" value="Verify" type="submit"
                           onClick={(e) => {
                               props.onBtnVerifySignUp(e, "nameSubmitCode", "codeSubmitCode");
                           }}/>
                </label>
        }
    </form>;


class SignInUpDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSignedIn: false,
            errorMessage: "",
            successfulSignUp: false,
            signedUpUsername: "",
            verifiedSignUp: false,
        };

        this.onBtnSignUp = this.onBtnSignUp.bind(this);
        this.onBtnSignIn = this.onBtnSignIn.bind(this);
        this.onBtnClose = this.onBtnClose.bind(this);
        this.onBtnVerifySignUp = this.onBtnVerifySignUp.bind(this);
        this.onBtnResendVerificationCode = this.onBtnResendVerificationCode.bind(this);
    }

    async onBtnSignUp(event) {
        event.preventDefault();

        const username = document.getElementById("username").value;
        const password = document.getElementById("new-password").value;
        const email = document.getElementById("email").value;
        let isValid, validationErrorMessage;

        [isValid, validationErrorMessage] = validateUsername(username);
        if(!isValid){
            this.setState({errorMessage: "The username is not a valid username. " + validationErrorMessage});
            return;
        }
        [isValid, validationErrorMessage] = validatePassword(password);
        if(!isValid){
            this.setState({errorMessage: "The password is not a valid password. " + validationErrorMessage});
            return;
        }
        [isValid, validationErrorMessage] = validateEmail(email);
        if(!isValid){
            this.setState({errorMessage: "The email is not a valid email. " + validationErrorMessage});
            return;
        }

        let [success, signUpMessage] = await this.props.onSignUpMethod(username, password, email);
        if(success){
            //this.setState({successMessage: "Signed up successfully!: " + signUpMessage});
            this.setState({errorMessage: "", successfulSignUp: true, signedUpUsername: username,});
        }
        else{
            this.setState({errorMessage: "Failed to sign up. " + signUpMessage});
        }
    }

    async onBtnSignIn(event) {
        event.preventDefault();
        console.log("Pressed sign in!");

        const usernameOrEmail = document.getElementById("signInName").value;
        const password = document.getElementById("current-password").value;
        let isValid, validationErrorMessage, errorMessage;

        [isValid, validationErrorMessage] = validateUsername(usernameOrEmail);
        if(!isValid) {
            errorMessage = validationErrorMessage;
            [isValid, validationErrorMessage] = validateEmail(usernameOrEmail);
            if(!isValid) {
                this.setState({errorMessage: "The name is not a valid username nor a valid email. " + errorMessage + "; " + validationErrorMessage});
                return;
            }
        }
        [isValid, validationErrorMessage] = validatePassword(password);
        if(!isValid){
            this.setState({errorMessage: "The password is not a valid password." + validationErrorMessage});
            return;
        }

        let [success, signInErrorMessage, user] = await this.props.onSignInMethod(usernameOrEmail, password);
        if(success){
            console.log("Sign in succeeded!");
            console.log(user);
            this.setState({user: user, isSignedIn: true});
            this.props.onSignInMethodSuccess();  // Updates Sign In button outside of this dialog.
            // TODO: "Use" the user session. Refresh? Exit dialog.
        }
        else{
            console.log("Sign in failed: " + signInErrorMessage);
            this.setState({errorMessage: "Sign in failed: " + signInErrorMessage});
        }
    }

    onBtnClose(event) {
        event.preventDefault();

        this.setState({errorMessage: false, successfulSignUp: false, signedUpUsername: "", verifiedSignUp: false});
                        // Can re-sign up, now.
                        // As well as sign in. Might be irrelevant, as the user's client is expected to "refresh".

        this.props.onCloseMethod();
    }

    // TODO: Send "verification succeeded" e-mail. Mailchimp FTW?
    async onBtnVerifySignUp(event, nameId=null, codeId=null) {
        event.preventDefault();

        let code = document.getElementById(codeId ? codeId : "sendVerificationCodeSignedUp").value;
        let name = nameId ? document.getElementById(nameId).value : this.state.signedUpUsername;

        try {
            await Auth.confirmSignUp(name, code, { forceAliasCreation: false });
            this.setState({verifiedSignUp: true});
            console.log('Verification code submitted successfully.');
        } catch (error) {
            this.setState({errorMessage: 'Error confirming sign up: ' + error.toString()});
            console.log('Error confirming sign up: ' + error.toString());
        }
    }

    async onBtnResendVerificationCode(event, nameId=null) {
        event.preventDefault();

        let name = nameId ? document.getElementById(nameId).value : this.state.signedUpUsername;

        let [isValid, validationErrorMessage] = validateUsername(name);
        if(!isValid) {
            let errorMessage = validationErrorMessage;
            [isValid, validationErrorMessage] = validateEmail(name);
            if(!isValid) {
                this.setState({errorMessage: "The name is not a valid username nor a valid email. " + errorMessage + "; " + validationErrorMessage});
                return;
            }
        }

        try {
            await Auth.resendSignUp(name);
            console.log('Verification code resent successfully.');
        } catch (err) {
            this.setState({errorMessage: 'Error resending verification code: ' + err.toString()});
            console.log('Error resending verification code: ' + err.toString());
        }
    }

    render() {
        return (
            this.state.isSignedIn ? <div/> : <div style={{
                width: "100vw", height: "100vh", position: "fixed",
                zIndex: 10001, backgroundColor: "#00000040",
                display: "flex", justifyContent: "center", alignItems: "center",
            }}>
                <div style={{
                    width: "40vw", height: "80vh",
                    margin: "0",
                    backgroundColor: "#aaa",
                }}>
                    <div style={{
                        width: "100%", height: "10%",
                        display: "flex", justifyContent: "right",
                    }}>
                        <CloseButton onClick={this.onBtnClose}/>
                        </div>
                    {this.state.successfulSignUp ?
                        <div
                            style={{height: "70%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <h1>Successfully signed up!</h1>
                            <p className="p-small">Thank you! I hope you'll enjoy the website!</p>
                            {this.state.verifiedSignUp ?
                                <div>
                                    <p className="p-small">Successfully verified code. You can close the panel.</p>
                                </div>
                                :
                                <>
                                    <p className="p-small">All that remains is confirming the verification code.</p>
                                    <form>
                                        <label htmlFor="sendVerificationCodeSignedUp">Send verification code: </label>
                                        <input id="sendVerificationCodeSignedUp" type="number" placeholder="######"
                                               style={{width: "7vw"}}/>
                                        <input type="submit" value="Confirm" onClick={this.onBtnVerifySignUp}/>
                                        <input type="submit" value="Resend Code"
                                               onClick={this.onBtnResendVerificationCode}/>
                                    </form>
                                </>
                            }
                        </div>
                        :
                        <div style={{
                            height: "70%",
                        }}>
                            <div style={{
                                height: "50%",
                                display: "flex", flexDirection: "row", justifyContent: "center",
                            }}>
                                <div style={{
                                    width: "50%", height: "100%",
                                    display: "flex", flexDirection: "column", justifyContent: "center",
                                }}>
                                    {/*Disable submit button until the inputs are valid.*/}
                                    <form onSubmit={this.onBtnSignUp}>
                                        <input id="username" name="signUpUsername"
                                               placeholder="Username" autoComplete="username"/>
                                        <input id="email" name="signUpEmail"
                                               placeholder="Email" autoComplete="username"/>
                                        <input id="new-password" name="signUpPassword" type="password"
                                               placeholder="Password" autoComplete="new-password"/>
                                        <input type="submit" value="Sign Up"/>
                                    </form>
                                    {/*<button onClick={this.onBtnSignUp}>Sign Up</button>*/}
                                </div>
                                <div style={{
                                    width: "50%", height: "100%",
                                    display: "flex", flexDirection: "column", justifyContent: "center",
                                }}>
                                    {/*Punish users that spam the button, prevent brute-forcing.*/}
                                    {/*Disable submit button until the inputs are valid.*/}
                                    <form onSubmit={this.onBtnSignIn}>
                                        <input id="signInName" name="signInName"
                                               placeholder="Username/Email" autoComplete="username"/>
                                        <input id="current-password" name="signInPassword" type="password"
                                               placeholder="Password" autoComplete="current-password"/>
                                        <input type="submit" value="Sign In"/>
                                    </form>
                                    {/*<button onClick={this.onBtnSignIn}>Sign In</button>*/}
                                </div>
                            </div>

                            <div style={{height: "20%",}}>
                                <DirectVerificationCodeForm onBtnVerifySignUp={this.onBtnVerifySignUp}
                                                            onBtnResendVerificationCode={this.onBtnResendVerificationCode}
                                                            verifiedSignUp={this.state.verifiedSignUp}/>
                            </div>
                        </div>}
                    <div style={{height: "10%",}}>
                        <p className="p-small" style={{color: "#f00"}}>{this.state.errorMessage}</p>
                    </div>
                    <div style={{height: "10%",}}/>
                </div>
            </div>
        );
    }
}

export default SignInUpDialog;