import React from "react";
import { ProfileList } from '../Profiles/Profile'

class AllProfilesPage extends React.Component {
    render() {
        return (
            <div className="middle-inner-part-margin">
                <h1>Community</h1>
                <p>This list is due to be implemented with a database.</p>
                <ProfileList/>
            </div>
        );
    }
}

export default AllProfilesPage;