import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import BNApp from './BNApp';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
} from "react-router-dom";

import AllProfilesPage from "./Components/Pages/AllProfilesPage";
import HomePage from "./Components/Pages/HomePage"
import ContactPage from "./Components/Pages/ContactPage";
import ToBeAddedPage from "./Components/Pages/ToBeAddedPage";

import AboutPage from "./Components/Pages/AboutPage";
import AboutWebsitePage from "./Components/Pages/AboutWebsitePage";

import HirePage from "./Components/Pages/HirePage";
import PortfolioPage from "./Components/Pages/PortfolioPage";
import ServicesPage from "./Components/Pages/ServicesPage";

import AdriftPage from "./Components/Pages/My Game Pages/AdriftPage";
import AebfPage from "./Components/Pages/My Game Pages/AebfPage";

import TestboxPage from "./Components/Pages/TestboxPage"
import DemoPage from "./Components/Pages/DemoPage"

import MyNavbar from "./Components/MyNavbar";

// https://aws.amazon.com/getting-started/hands-on/build-react-app-amplify-graphql/module-three/?e=gs2020&p=build-a-react-app-two
/*import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);*/

const root = ReactDOM.createRoot(document.getElementById('root'));
{/*https://stackoverflow.com/questions/55119377/react-js-constructor-called-twice*/}
root.render(
  <>
      <Router>
          <BNApp />
      </Router>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export {AllProfilesPage, HomePage, ContactPage, ToBeAddedPage,
    AboutPage, AboutWebsitePage,
    HirePage, PortfolioPage, ServicesPage,
    AdriftPage, AebfPage,
    TestboxPage, DemoPage,
    MyNavbar};