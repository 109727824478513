import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faItchIo, faLinkedin,
    //faTwitter, faYoutube,
    //faBandcamp, faSoundcloud,
} from '@fortawesome/free-brands-svg-icons'

class ContactPage extends React.Component {
    componentDidMount() {
        document.title = "Contact - Ido Waisbart"
        this.isMobile = this.isMobile.bind(this);
    }

    // For explanation, see the same method at MePics.js.
    isMobile(){
        return window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
    }

    render() {
        let isMobile = this.isMobile();

        return (
            <div className="middle-inner-part-margin"
                 style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <h1>Contact Me</h1>
                <p>Ido Waisbart</p>
                <a href="mailto:idowaisbart@gmail.com">idowaisbart@gmail.com</a>
                <p>(+972)58-6401545</p>
                <div style={{height: "2.5vh"}}/>
                <div className="social-media-container" style={{width: isMobile ? "auto" : "auto",
                    display: "flex", justifyContent: "space-between"}}>
                    <a href="https://github.com/Ido-Waisbart" style={{color: "#6e5494",}}><FontAwesomeIcon
                        icon={faGithub}/></a>
                    <a href="https://www.linkedin.com/in/ido-waisbart/" style={{color: "#0077b5",}}><FontAwesomeIcon
                        icon={faLinkedin}/></a>
                    <a href="mailto:idowaisbart@gmail.com" style={{color: "#fff",}}><FontAwesomeIcon
                        icon={faEnvelope}/></a>
                    <a href="https://blawnode.itch.io/" style={{color: "#fa5c5c",}}><FontAwesomeIcon
                        icon={faItchIo}/></a>
                    {/*<a href="https://twitter.com/blawnode"><FontAwesomeIcon icon={faTwitter} style={{color: "#1da1f2"}}/></a>*/}
                    {/*<a href="https://m.youtube.com/channel/UCldotnKAENFMJRmH9esOqxA"><FontAwesomeIcon icon={faYoutube}/></a>*/}
                    {/*<a href="https://soundcloud.com/blawnode"><FontAwesomeIcon icon={faSoundcloud}/></a>*/}
                    {/*<a href="https://blawnode.bandcamp.com/"><FontAwesomeIcon icon={faBandcamp}/></a>*/}
                </div>
            </div>
        );
    }
}

export default ContactPage;