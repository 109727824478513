// https://dev.to/gedalyakrycer/create-an-email-list-with-react-mailchimp-965
// https://github.com/GedalyaKrycer/genhybridsystems/blob/main/src/components/ui/InputField/InputField.js

import React, {useState} from 'react';
//import './mcFormStyles.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";

//Checks if all the fields are filled and if an @ sign is used in the email field
const validateInput = values => {
    return (values.some(f => f === "")
        || values[0].indexOf("@") === -1);
}

const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        // TODO OPTIONAL: Regex FTW!
        e.preventDefault();
        email &&
        // firstName &&
        // lastName &&
        email.indexOf("@") > -1 &&
        email.indexOf(".") > email.indexOf("@") &&
        onValidated({
            // EMAIL: email,
            // MERGE1: firstName,
            // MERGE2: lastName,
            MERGE0: email,
        });
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <h3>{status === "success" ? "Success!" : "Sign up to mailing list?"}</h3>
            {/*Mobile: 4.5vw*/}
            {/*PC: 1.2vw*/}
            {/*Old: 16px*/}
            <p style={{margin: "auto"}}>Would you like to hear more from me, in mail form?<br/>
                I'm meaning to send only updates about <b>large</b> stuff happening in this mailing list.<br/>
                Like major updates to games I make, mostly.<br/>
                (The mail might appear in your "Promotions" mail tab.)</p>
            <div style={{height: "2vh"}}/>

            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">
                    Sending...
                </div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status !== "success" ? (
                <>
                    {/*<InputField
                    label="First Name"
                    onChangeHandler={setFirstName}
                    type="text"
                    value={firstName}
                    placeholder="Jane"
                    isRequired
                />*/}
                    {/*<label>
                    "First Name"
                    <input
                        onChange={(e) => setFirstName(e.target.value)}
                        type="text"
                        placeholder="Jane"
                        value={firstName}
                        required
                    />
                </label>*/}

                    {/*<InputField
                    label="Last Name"
                    onChangeHandler={setLastName}
                    type="text"
                    value={lastName}
                    placeholder="Doe"
                    isRequired
                />*/}
                    {/*<label>
                    "Last Name"
                    <input
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        placeholder="Doe"
                        value={lastName}
                        required
                    />
                </label>*/}

                    {/*<InputField
                    label="Email"
                    onChangeHandler={setEmail}
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    isRequired
                />*/}
                    <label style={{fontSize: "1.5rem"}}>
                        Email<i> </i>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="your@email.com"
                            value={email}
                            required
                        />
                    </label>
                    <div style={{height: "2vh"}}/>
                </>
            ) : null}

            {/*<InputField
                label="subscribe"
                type="submit"
                formValues={[email, firstName, lastName]}
            />*/}
            {(status !== "success" && <input
                style={{fontSize: "1.5rem"}}
                className='primaryBtn primaryBtn--big g__justify-self-center'
                type='submit'
                value="Sign up to mailing list"
                disabled={validateInput([email])}
            />)}
        </form>
    );
};

const MailchimpFormContainer = props => {
    const MAILCHIMP_U = "9dabbbf2b8177e5ae3a027cc7";
    const MAILCHIMP_ID = "ee75cc6454";

    const postUrl = `https://xyz.us17.list-manage.com/subscribe/post?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`;
    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => {
                            subscribe(formData);
                        }}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpFormContainer;