import React from "react";

class MePics extends React.Component {
    constructor(props) {
        super(props);

        this.isMobile = this.isMobile.bind(this);
    }

    // A primitive copy-paste of MyNavbar.js. Should move into a separate script?
    isMobile(){
        return window.matchMedia && window.matchMedia("(max-width: 480px)").matches;
    }

    render() {
        //let picSize = this.props.picSize;
        let pageStyle = this.props.pageStyle;
        let isMobile = this.isMobile();
        let leftTransform = isMobile ? "-50" : "0";
        return (
            <div style={{
                position: "relative",
                left: isMobile ? "auto" : "46vw",
                top: isMobile ? "0vh" : "17.5vh",
                margin: isMobile ? "0 auto" : "inherit",
                width: 0, ...this.props.style
            }}
            >
                {/*TODO: Ensure that this div's position is correct and flexible.*/}
                <img src="/Images/Blawnode Logo BG-less.webp"
                     alt="BN Logo"
                     style={{
                         left: isMobile ? "-12vw" : "-6vw",
                         top: "-3vh",
                         rotate: "-5deg",
                         transform: "translate(" + leftTransform + "%, 0)",
                     }}
                     className={("dropShadow " + pageStyle)}/>
                <img src="/Images/AustriaMeCut.webp"
                     alt="Ido Selfie"
                     style={{
                         left: isMobile ? "12vw" : "6vw",
                         top: "3vh",
                         rotate: "5deg",
                         transform: "translate(" + leftTransform + "%, 0)",
                         position: isMobile ? "relative" : "auto",  // isMobile: To ensure that MePic has this element's height, approximately.
                     }}
                     className={("dropShadow " + pageStyle)}/>
            </div>
        );
    }
}

MePics.defaultProps = {
    //picSize: "40vh",
    pageStyle: "me-pics-size-home",
    //picSize: variables.mePicsSizeHome,  // https://stackoverflow.com/questions/52900207/how-to-use-scss-variables-into-my-react-components
};

export { MePics };