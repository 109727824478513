// TODO: Use S3 buckets to contain resumes, and then load them onto the page.

import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import {MyResumeContainer} from "../Resume";  // Currently problematic to use on AWS (online).

const hireButtonStyle = {width: "20vw", height: "20vh", margin: 0,
    maxWidth: "45%", minWidth: "15vw", fontSize: "1.75vw",};

// Size won't change when zooming. Use this?: clamp(1.5rem, 1.4vw, 3rem)?
const HireButton = (props) => {
    return <a href={props.href} style={{...hireButtonStyle, display: "flex", justifyContent: "center",}}>
        <button style={{width: "inherit", height: "inherit", margin: 0,}}>
            {props.text}
        </button>
    </a>;
}

// ASSUMPTION: This component's given children are only <HireButton>'s.
// ASSUMPTION: This container won't have more than 3 children. (Style worsens.)
class HireButtonContainer extends React.Component{
    constructor(props) {
        super(props);

        this.calculateJustifyContent = this.calculateJustifyContent.bind(this);
    }

    calculateJustifyContent(){
        let childCount = this.props.children.length;
        return childCount > 1 ? "space-between" : "center";
    }

    render(){
        return <div style={{display: "flex", justifyContent: this.calculateJustifyContent()}}>
            {this.props.children}
        </div>;
    }
}

const MyOfflineResumeContainer = (props) =>{
    return <div style={{
        width: "100%", height: "40vh",
        display: "flex", justifyContent: "space-around", alignItems: "center",
    }}>
        <a target="_blank"
           href="https://github.com/Ido-Waisbart/Resumes/blob/main/Resume%20-%20Ido%20Waisbart%20(English).pdf">
            <button style={{width: "20vw", height: "10vw", fontSize: "1.75vw"}}>View English Résumé
            </button>
        </a>
        <a target="_blank"
           href="https://github.com/Ido-Waisbart/Resumes/blob/main/Resume%20-%20Ido%20Waisbart%20(Hebrew).pdf">
            <button style={{width: "20vw", height: "10vw", fontSize: "1.75vw"}}>View Hebrew Résumé
                (עברית)
            </button>
        </a>
    </div>;
}

function getOffset( el ) {
    let rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.pageXOffset,
        top: rect.top + window.pageYOffset,
        width: rect.width || el.offsetWidth,
        height: rect.height || el.offsetHeight
    };
}

// https://stackoverflow.com/questions/8672369/how-to-draw-a-line-between-two-divs
function connect(div1, div2, color, thickness) { // draw a line connecting elements
    let off1 = getOffset(div1);
    let off2 = getOffset(div2);
    // bottom right
    let x1 = off1.left + off1.width;
    let y1 = off1.top + off1.height;
    // top right
    let x2 = off2.left + off2.width;
    let y2 = off2.top;
    // distance
    let length = Math.sqrt(((x2-x1) * (x2-x1)) + ((y2-y1) * (y2-y1)));
    // center
    let cx = ((x1 + x2) / 2) - (length / 2);
    let cy = ((y1 + y2) / 2) - (thickness / 2);
    // angle
    let angle = Math.atan2((y1-y2),(x1-x2))*(180/Math.PI);
    // make hr
    let htmlLine = "<div style='padding:0; margin:0; height:" + thickness + "px; background-color:"
        + color + "; line-height:1px; position:absolute; left:" + cx + "px; top:" + cy + "px;" +
        "width:" + length + "px; -moz-transform:rotate(" + angle + "deg); -webkit-transform:rotate(" + angle + "deg);" +
        "-o-transform:rotate(" + angle + "deg); -ms-transform:rotate(" + angle + "deg);" +
        "transform:rotate(" + angle + "deg); z-index=-1' />";
    //
    // alert(htmlLine);
    document.body.innerHTML += htmlLine;
}

class HirePage extends React.Component {
    componentDidMount() {
        document.title = "Hire - Ido Waisbart";
        /*let startPoint = document.getElementById("startPoint");
        let endPoint = document.getElementById("endPoint");
        connect(startPoint, endPoint, "#000", 3);*/
    }

    render() {
        return (
            <div className="MyPage middle-inner-part-margin" style={{textAlign: "left"}}>
                <div className="summary-container"
                     style={{
                         margin: "0 0 5vh 0", textAlign: "justify", minHeight: "80vh",
                         display: "flex",
                         alignItems: "center",
                     }}>
                    <div style={{height: "fit-content", display: "flex",}}>
                        <div>
                            <h1 style={{marginTop: 0}}>Hire Me</h1>
                            <hr/>
                            <p>
                                Are you looking for a <b>web developer?</b><br/>
                                I am interested in a job!
                            </p>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <p>I develop React.js websites, like this one.</p>
                                <div id="startPoint"/>
                            </div>

                            <p>In addition to React web development, I develop <b>Unity games</b> and <b>.NET
                                software</b>.
                            </p>

                            <div style={{height: "3vh"}}/>

                            <HireButtonContainer>
                                <HireButton href="#resume" text="View my résumé"/>
                                <HireButton href="services" text="My services"/>
                            </HireButtonContainer>
                        </div>

                        <div style={{width: "10vw"}}/>

                        <ol className="rounded-list" style={{
                            width: "60%", margin: "0", height: "inherit",
                            justifyContent: "space-between"
                        }}>
                            <p style={{textAlign: "center", margin: "0", width: "100%"}}>My experience<br/>
                                includes:</p>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div id="endPoint"/>
                                <li><a href="#nolink">A personal website - the very site you're at, right now.</a></li>
                            </div>
                            <li><a href="https://www.freecodecamp.org/learn/front-end-development-libraries/">freeCodeCamp's
                                course, "Front End Development Libraries".<br/>
                                All that's left is 2 code
                                assignments. <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                            </a>
                            </li>
                            <li><a href="#nolink">3 years of HTML/CSS/JS studying.</a></li>
                        </ol>
                    </div>
                </div>

                {/*<div style={{height: "5vh"}}/>*/}

                <section style={{height: "100vh"}}>
                    <div id="resume" style={{height: "20vh"}}/>

                    <h1 style={{textAlign: "center", height: "10vh",}}>Résumé</h1>

                    {/*TODO: This will be added only when I'll have access to files.
                    I'd rather not pay anything for this, while I don't have an income.*/}
                    {/*<MyResumeContainer/>*/}

                    <MyOfflineResumeContainer/>

                    {/*<div style={{height: "25vh"}}/>*/}
                </section>
            </div>
        );
    }
}

export default HirePage;