// https://javascript.plainenglish.io/how-to-upload-files-to-aws-s3-in-react-591e533d615e

//  As of the 21/02/2024, 19:25 GMT+2, this is the only place I've touched that uses the S3 buckets, with a few mentions in .json files.
//      The only file importing this file, is BNApp - and it doesn't even use it, ATM. (Commented out.)

import React ,{useState} from 'react';
import AWS from 'aws-sdk'

const S3_BUCKET ='idow-profile-images';
const REGION ='eu-central-1';  // EU (Frankfurt) eu-central-1. I hope that's the right one.

AWS.config.update({
    accessKeyId: 'AKIARKQDJC4SHWWUTT6J',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

const UploadImageToS3UsingNativeSdk = () => {

    const [progress , setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(null);
    const [uploadMessage, setUploadMessage] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const uploadFile = (file) => {

        setUploadMessage(null);

        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100))
            })
            .send((err) => {
                if (err) {
                    console.log(err);
                    setUploadSuccess(false);
                    setUploadMessage(err);
                }
                else {
                    let msg = "Awesome. Uploaded the image successfully, I think."
                    console.log(msg);
                    setUploadSuccess(true);
                    setUploadMessage(msg);
                }
            })
    }


    return <div>
        <div>Native SDK File Upload Progress is {progress}%</div>
        <input type="file" onChange={handleFileInput}/>
        <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
        {uploadSuccess && <div>
            <p>Image uploaded successfully!</p>
            <br/>
            <p>Wait a few seconds for the changes to propagate.</p>
        </div>}
        {!uploadSuccess && uploadMessage != null && <>
            <p>Failed to upload image:</p>
            <br/>
            <p>{uploadMessage}</p>
        </>}
    </div>
}

export default UploadImageToS3UsingNativeSdk;