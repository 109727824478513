import React from "react";

const vlogEmoteObjectPath = "vlogEmoteDictionary.json";
let vlogEmoteObject;
let wasVlogEmoteObjectSuccessfullyFetched = false;
fetch(vlogEmoteObjectPath)
    .then((response) => {
        return response.json();
    })
    .then((json) => {
        vlogEmoteObject = json;
        wasVlogEmoteObjectSuccessfullyFetched = true;
    })
    .catch(error => {
        console.error('Error fetching vlogEmoteDictionary: ', error);
    });
//let characterEmotes = vlogEmoteObject.map((characterEmoteObject) => 1);
// let vlogEmoteDictionary = vlogEmoteObject.map((characterEmoteObject) =>
//     ({(1, 2): 3})
// );

class ItchArticle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blogTitle: this.props.blogTitle,
            link: this.props.link,
            nolink: this.props.nolink,
            writer: this.props.writer,
            platform: this.props.platform,
            textElements: this.props.textElements,
            font: this.props.font,
            vlogPath: this.props.vlogPath,
            tags: this.props.tags,
        };

        //this.toggleMenu = this.toggleMenu.bind(this);

        if(this.props.vlogPath != null)
            this.loadVlogFromJson(this.props.vlogPath);
        else{
            //console.log("No vlog path to extract data from.");
        }
    }

    ParseBlogDialogueTextToHtmlElement(dialogueText) {
        let character = dialogueText.character,
            emote = dialogueText.emote;

        if(emote === ""){
            return <p><img src={"https://opengameart.org/sites/default/files/skeleton-run.gif"} alt="Blawnode Emote"/>&nbsp;
                {dialogueText.text}</p>;
        }

        let characterEmotes = vlogEmoteObject.filter((characterEmotesObject) =>
            character === characterEmotesObject.character)[0].emotes;

        let emoteObject = characterEmotes.filter((emoteObject) =>
            emote === emoteObject.emote)[0];

        let imgUrl;

        if(!wasVlogEmoteObjectSuccessfullyFetched){
            //imgUrl = "/Images/missingBnExpression.png"
            console.log("YOOOOOOOOOOOOOOO")
        }
        else if(emoteObject === undefined){
            //throw new Error("Can't find " + emote);//
            console.warn("Can't find " + emote);

            // TODO: A missing texture should appear under either of these circumstances:
            //      1. No internet connection.
            //      2. Emote ID is not linked in characterEmotes and vlogEmoteObject.
            // imgUrl = default  // TODO: missing texture?
            //imgUrl = "/Images/missingBnExpression.png"
            console.log("AHHHHHHHHHHHHHHHHHHHH")
        }
        else{
            imgUrl = emoteObject.imgUrl;
            // TODO: What if the url simply isn't reachable?? (Above TODO, case 1 - no internet connection)
            console.log("coo'")
        }

        return <p><img src={imgUrl} alt="Blawnode Emote"
                       onError={({ currentTarget }) => {
                           currentTarget.onerror = null; // prevents looping
                           currentTarget.src="/Images/missingBnExpression.png";
                       }}/>&nbsp;
            {dialogueText.text}</p>;
    }

    ParseBlogTextToHtmlElement(text) {
        return <p>{text.text}</p>;
    }

    ParseBlogImageToHtmlElement(image) {
        //return <p>tobeimplemented:IMAGE</p>;

        let imgUrl = image.imgUrl;
        let imgSrc = image.imgSrc;  // TODO: Update the src's in all blog posts. Make sure they're saved locally on GitHub or S3 buckets or smth.
        let imgAlt = image.imgAlt;
        let caption = image.caption;
        let alignment = image.alignment;

        console.log(imgSrc)
        console.log(imgUrl)

        if(imgUrl === undefined){
            return <p style={{textAlign: alignment}}>
                <img src={imgSrc} alt={imgAlt} title={caption}/>
                <p>caption</p>
            </p>;
        }
        else {
            return <p style={{textAlign: alignment}}>
                <a href={imgUrl}>
                    <img src={imgSrc} alt={imgAlt} title={caption}/>
                </a>
                <p style={{textAlign: alignment}}>{caption}</p>
            </p>;
        }
    }

    ParseBlogListToHtmlElement(list) {
        let items = list.items;

        return <ul>{
            items.map((listItem, i) =>
                <li style={{textAlign: "left",}} key={i}>{listItem}</li>)
        }</ul>;
    }

    // Blog Line types: dialogueText, text, image, list, space
    ParseBlogLineToHtmlElement(blogLine){
        switch (blogLine.type){
            case "text":
                return this.ParseBlogTextToHtmlElement(blogLine)
            case "dialogueText":
                return this.ParseBlogDialogueTextToHtmlElement(blogLine)
            case "image":
                return this.ParseBlogImageToHtmlElement(blogLine)
            case "list":
                return this.ParseBlogListToHtmlElement(blogLine)
            case "space":
                return <br/>
            default:
                throw new Error("hey, no, not allowed: " + JSON.stringify(blogLine));
        }
    }

    // TODO: blawnode16px.shock1 has no imgUrl yet. A placeholder image was used instead.
    loadVlogFromJson(vlogPath){
        fetch(vlogPath)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                let blogTitle = json.blogTitle,
                    link = json.hasOwnProperty("link") ? json.link : this.props.link,
                    nolink = !json.hasOwnProperty("link"),
                    writer = json.writer,
                    platform = json.hasOwnProperty("platform") ? json.platform : this.props.platform,
                    font = json.hasOwnProperty("font") ? json.font : this.props.font,
                    tags = json.hasOwnProperty("tags") ? json.tags : this.props.tags,
                    textElements = json.blogLines.map((blogLine) => {
                        return this.ParseBlogLineToHtmlElement(blogLine);
                    });

                /*<p><img src="https://img.itch.zone/aW1nLzk5MDY0ODUucG5n/original/sxv%2BrZ.png"
                                         className="unselectable"/>&nbsp;
                                     Ahh, continued jam games.</p>*/

                this.setState({
                    blogTitle: blogTitle,
                    link: link,
                    nolink: nolink,
                    writer: writer,
                    platform: platform,
                    textElements: textElements,
                    font: font,
                    tags: tags,
                });
            })
            .catch(error => {
                console.error('Error at loadVlogFromJson(): ', error);
            });
    }

    render() {
        return (
            <article className="itch-article-wrapper" style={{fontFamily: this.state.font}}>
                <div className="article-title-wrapper"
                     style={{fontFamily: "inherit"}}>
                    <h1 style={{fontFamily: "inherit"}}>
                        {this.state.blogTitle}
                    </h1>
                    <h5>
                        <a {... this.state.nolink ? {} : {href: this.state.link}}>
                            {"By: " + this.state.writer + ", " + this.state.platform}
                        </a>
                    </h5>
                    <hr/>
                </div>

                <div className="itch-article-content"
                     style={{fontFamily: "inherit"}}>
                    {/*<p>Is anyone able to see this post, except for the team?</p>
                    {/*<p>This is where more lines would go. Just like in itch.io!</p>**}*/}
                    {/*https://stackoverflow.com/questions/38130423/react-how-to-add-key-when-pushing-to-an-array*/}
                    {this.state.textElements.map((element, key) => React.cloneElement(element,{key})
                    )}
                </div>
            </article>
        );
    }
}

ItchArticle.defaultProps = {
    blogTitle: "Placeholder Title",
    link: "https://blawnode.itch.io/",
    nolink: false,
    writer: "Ido Waisbart",
    platform: "itch.io",
    textElements: [
        <p>Placeholder/Error Message</p>,
        <p>This itch articles's element list is missing.</p>
    ],
    font: "inherit",
    vlogPath: null,
    tags: [],
}

export default ItchArticle;