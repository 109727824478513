import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import {Carousel, CarouselItem} from "react-bootstrap";

const onClosePanel = (parent) => {
    parent.setState({showServiceDescriptionPanel: false});
}

const services = {
    "chiptunes": {
        title: "Chiptunes",
        textElementContainer: <>
            <p>I make 8-bit style chiptune music!</p>
            <p>My favourite kinds of music to create is:<br/>
                Boss music, evil music, upbeat music, and dramatic music.<br/>
                But I compose music with all atmospheres and moods.</p>
            <p>I use FamiTracker to compose my music,<br/>
                often with either the 2A03 or the VRC6 modules.<br/>
                This means I can also export .NSF files!</p>
            <p>I upload a lot of my music on <a href="https://www.youtube.com/@blawnode">my YouTube channel</a>,<br/>
                and sometimes to my <a href="https://www.soundcloud.com/blawnode">Soundcloud</a> and <a href="https://www.bandcamp.com/blawnode">Bandcamp</a>, too.</p>
            <p>My biggest musical creations are:<br/>
                - A dramatic, small chiptune album, <a href="https://www.youtube.com/playlist?list=PLVUasjnz_o9qctMGyxggTE7bhcbESyfQx">"Demon's Cage"</a>,<br/>
                - A bombastic boss track for a game by Adrift Program Team, <a href="https://blawnode.itch.io/adrift">"Adrift Program"</a>.
            </p>
        </>,
        imageURLSAndDecriptions: [
            ["https://f4.bcbits.com/img/a2264222497_10.jpg", "Demon's Cage album cover"],],
    },
    "translations": {
        title: "Translations",
        textElementContainer: <>
            <p>I am proficient in both Hebrew - my mother tongue, and English.<br/>
                I've spent my childhood on the internet, socializing and programming alike,<br/>
                and before you knew it, my English was as good - if not better - than my Hebrew.</p>
            <p>At the gifted class of my high school, Makif Gimel,<br/>
                I've finished 5 units of matriculation at English,<br/>
                in addition to 5 units at Hebrew, as well.</p>
            <p>My greatest translation achievement is an English-to-Hebrew translation of NomnomNami's
                <i> </i><a href="https://nomnomnami.itch.io/syrup-and-the-ultimate-sweet">Syrup and the Ultimate Sweet</a>, a cute little visual novel.</p>
        </>,
        imageURLSAndDecriptions: [["/Images/Services Images/syrupHebrew.webp", "Hebrew Translation of Syrup and the Ultimate Sweet"],],
    },
    "pixelart": {
        title: "Pixel Art",
        textElementContainer: <>
            <p>I love making pixel art and animations with Aseprite!<br/>
            Practicing pixels since 2014, I've come to love these squares.</p>
            <p>While I specialize with characters, I can also create machines, rooms, items and more.</p>
            <p>I make all my music videos' art on <a href="https://www.youtube.com/@blawnode">my YouTube channel</a>.<br/>
            I also used to publish art on my Twitter account.</p>
            <p>Additional noteworthy achievements of mine include:<br/>
                - Art for my team's submission for the Brackeys Jam 2021, <a href="https://blawnode.itch.io/temporary-pizza-mens-game">"Light Speed Delivery"</a>,<br/>
                - Art for my submission to Everhood's custom battle contest, <a href="https://github.com/blawnode/EverhoodCackler">"The Cackler".</a><br/>
            </p>
        </>,
        imageURLSAndDecriptions: [["/Images/Services Images/Art Service.webp", "Art Service"],
            ["/Images/Services Images/Nowak Transparent.webp", "Nowak from Brutal Orchestra Animation"],
            ["/Images/Services Images/PizzaMotor.webp", "Pizza Motorist Animation"],//
            ["/Images/Services Images/Papers Ido.webp", "Papers Please-styled self-portait"],],
    },
    "gamedev": {
        title: "Game Development",
        textElementContainer: <>
            <p>My dream job is making games, other than web development.<br/>
            Thus, I've learnt how to make games for years since 2018.</p>
            <p>I make games with Unity and C#, but I'm also interested in Godot Engine.</p>
            <p>I'm currently making an Overcooked-like game with a team of 6:
            <a href="https://www.itch.io/blawnode/adrift">Adrift Program</a> (due to be released soon, 2023!)</p>
            <p>Additional games and achievements include:<br/>
                - A solo-made casual mobile game, <a href="https://www.itch.io/blawnode/tripletriangle">Triple Triangle</a>,<br/>
                - A Unity RPG framework the likes of Final Fantasy and Epic Battle Fantasy, <a href="https://www.github.com/blawnode/the-rock-simulator">The Rock Simulator</a>,<br/>
                - An in-development Unity game for the Open University's OOP .NET workshop course, based upon The Rock Simulator.<br/>
            </p>
        </>,
        imageURLSAndDecriptions: [["/Images/Services Images/Adrift Gameplay.webp", "Adrift Program"],
            ["/Images/Services Images/TripleTriangle.webp", "Triple Triangle"],
            ["/Images/Services Images/Rock Simulator.webp", "The Rock Simulator"],
        ],
    },
    "webdev": {
        title: "Web Development",
        textElementContainer: <>
            <p>I make websites with React.js and its extended family:</p>
            <p>JavaScript/TypeScript, CSS/SASS, HTML,</p>
            <p>In addition to more web services:<br/>
                Bootstrap and FontAwesome.</p>
            <p>I've deployed my own personal website, www.idow.xyz,<br/>
                using AWS Amplify, Cognito, Route 53 and S3 buckets.<br/></p>
            <p>I've also completed freeCodeCamp's course on<br/>
                front end development libraries, and earned a <a href="https://freecodecamp.org/certification/idowaisbart/front-end-development-libraries">certificate</a>!<br/>
                In it, I've learnt Bootstrap, jQuery, SASS, React.js and Redux.</p>
        </>,
        imageURLSAndDecriptions: [["/Images/Tech Icons/React/logo512.webp", "React.js"],],
    },
};

const ServiceDetailsPanel = (props) => {
    function ClosePanel() {
        props.onClosePanel();  // For parent to change its state
    }

    const CloseButtonRow = <div style={{display: "flex", justifyContent: "end",}}>
        <a style={{
            color: "#fff", right: 0, position: "relative", width: "7.5vh", height: "7.5vh",
            display: "flex", justifyContent: "center", alignItems: "center",
        }}
           href="#"
           onClick={ClosePanel}>
            <FontAwesomeIcon icon={faX}/>
        </a>
    </div>;
    const TextColumn = <div style={{width: "50%"}}>
        <h1>{props.title}</h1>
        {props.textElementContainer}
    </div>;


    const sameSizeStyle = {width: "100%", height: "100%",};
    const sameSizeStyleImg = {width: "max-content", height: "100%",};
    const MediaColumn = <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "35%"}}>
        {/*<img src="/Images/Services Images/DotNSF.webp"
             alt=".NSF"
             style={{width: "10vw", imageRendering: "pixelated",}}/>
        <img src="/Images/Services Images/FamiTracker.webp"
             alt="FamiTracker"
             style={{width: "10vw",}}/>
        <img src="/Images/Services Images/Pixel Note Big.webp"
             alt="Big Pixel Note"
             style={{width: "10vw", imageRendering: "pixelated",}}/>
        <img src="/Images/Services Images/Pixel Note Medium.webp"
             alt="Medium Pixel Note"
             style={{width: "5vw", imageRendering: "pixelated",}}/>
        <img src="/Images/Services Images/Pixel Note Small.webp"
             alt="Small Pixel Note"
             style={{width: "2.5vw", imageRendering: "pixelated",}}/>*/}
        <Carousel variant="dark" style={{width: "100%", aspectRatio: "1 / 1",}}>
            {/*<Carousel.Item style={sameSizeStyle}>
                <iframe title="Game Youtube Video" src={props.ytLink}
                        style={sameSizeStyle}/>
            </Carousel.Item>*/}
            {props.imageURLSAndDecriptions.map((screenshot, index)=>
                {
                    // A screenshot consists of a src ([0]), and an alt ([1]).
                    let src = screenshot[0], alt = screenshot[1];
                    return <Carousel.Item style={sameSizeStyle} key={index}>
                        <img
                            src={src}
                            alt={alt}
                            style={sameSizeStyle}
                        />
                    </Carousel.Item>;}
            )}
        </Carousel>
    {/*    VideosAndScreenshots.defaultProps = {
    ytLink: "https://www.youtube.com/embed/XIMLoLxmTDw?autoplay=0&mute=0&controls=0",
    screenshots: [],
    getScreenshotsFrom: null,
    style: {},
}*/}
    </div>

    return <div id="ChiptunesService" className="TotalShadow">
        <div style={{
            position: "absolute", left: "5vw", top: "5vh",
            width: "90vw", height: "80vh", margin: 0,
            backgroundColor: "rgba(132, 126, 135, 0.9)",
        }}>
            {CloseButtonRow}
            <div style={{display: "flex", height: "65vh",}}>
                <div style={{width: "5%"}}/>
                {MediaColumn}
                <div style={{width: "5%"}}/>
                {TextColumn}
                <div style={{width: "5%"}}/>
            </div>
        </div>
    </div>;
}

/* TODO OPTIONAL: Make the services panel span the whole screen, except for the navbar. */

class ServicesPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {showServiceDescriptionPanel: false,};  // TODO: There's definitely a better way to handle the showing, right?

        this.OpenServiceDetailsPanel = this.OpenServiceDetailsPanel.bind(this);
    }

    componentDidMount() {
        document.title = "Services - Ido Waisbart";
    }

    OpenServiceDetailsPanel(serviceName){
        this.setState({showServiceDescriptionPanel: true, chosenService: serviceName,});
    }

    GetServiceDetailsPanel(){
        let serviceName = this.state.chosenService;
        let [title, textElementContainer, imageURLSAndDecriptions] = Object.values(services[serviceName]);
        return <ServiceDetailsPanel title={title}
                             textElementContainer={textElementContainer}
                                    imageURLSAndDecriptions={imageURLSAndDecriptions}
                             onClosePanel={() => onClosePanel(this)}
                             isDebug={false}/>;
    }

    render() {
        return (
            <div className="ServicesPage" style={{width: "100%", height: "100%",}}>
                <div className="ServicesGridPanel">
                    <div style={{width: "100%", height: "100%", display: "flex", flexWrap: "wrap"}}>
                        {/*<td style={{background: "#cbdbfc"}}><a href="#sick">1</a></td>*/}
                        {/*<a href="#sick" style={{background: "#99e550", width: "67%",}}>*/}
                        <a className="service-grid-cell" style={{background: "#99e550", width: "67%",}}
                           onClick={() => this.OpenServiceDetailsPanel("webdev")}>
                            <img src="/Images/Services Images/React White.webp"
                                 alt="Webdev backdrop"
                                 className="service-grid-cell-backdrop"
                                 style={{position: "absolute", margin: 0, width: "30vh",
                                     filter: "brightness(0.25) opacity(0.5)",
                                     userDrag: "none",
                                     WebkitUserDrag: "none",
                                     userSelect: "none",
                                     MozUserSelect: "none",
                                     WebkitUserSelect: "none",
                                     msUserSelect: "none",}}/> {/*https://stackoverflow.com/questions/12906789/preventing-an-image-from-being-draggable-or-selectable-without-using-js*/}
                            <p style={{zIndex: 1}}>Websites</p>
                        </a>
                        {/*<a href="#sick" style={{background: "#d77bba", width: "33%",}}>*/}
                        <a className="service-grid-cell" style={{background: "#d77bba", width: "33%",}}
                           onClick={() => this.OpenServiceDetailsPanel("translations")}>
                            <img src="/Images/Services Images/AaBbCc.webp"
                                 alt="Translation backdrop"
                                 className="service-grid-cell-backdrop"
                                 style={{position: "absolute", margin: 0, width: "40vh",
                                     filter: "brightness(0.25) opacity(0.5)",}}/>
                            <p style={{zIndex: 1}}>Translations</p>
                        </a>
                        {/*<a href="#sick" style={{background: "#fbf236", width: "33%",}}>*/}
                        <a className="service-grid-cell" style={{background: "#fbf236", width: "33%",}}
                           onClick={() => this.OpenServiceDetailsPanel("pixelart")}>
                            <img src="/Images/Services Images/Pixel Coin.webp"
                                 alt="Pixel art backdrop"
                                 className="service-grid-cell-backdrop"
                                 style={{position: "absolute", margin: 0, width: "30vh",
                                     filter: "brightness(0.25) opacity(0.5)",
                                     imageRendering: "pixelated",}}/>
                            <p style={{zIndex: 1}}>Pixel Art</p>
                        </a>
                        {/*<a href="#sick" style={{background: "#d95763", width: "34%",}}>*/}
                        <a className="service-grid-cell" style={{background: "#d95763", width: "34%",}}
                           onClick={() => this.OpenServiceDetailsPanel("gamedev")}>
                            <img src="/Images/Services Images/CS.webp"
                                 alt="Game programming backdrop"
                                 className="service-grid-cell-backdrop"
                                 style={{position: "absolute", margin: 0, width: "40vh",
                                     filter: "brightness(0.25) opacity(0.5)",}}/>
                            <p style={{zIndex: 1}}>Game Programming</p>
                        </a>
                        {/*<a href="#chiptunes" style={{background: "#5fcde4", width: "33%",}} onClick={this.OpenChiptunes}>*/}
                        <a className="service-grid-cell" style={{background: "#5fcde4", width: "33%",}}
                           onClick={() => this.OpenServiceDetailsPanel("chiptunes")}>
                            <img src="/Images/Services Images/Pixel Note Big.webp"
                                 alt="Chiptune backdrop"
                                 className="service-grid-cell-backdrop"
                                 style={{position: "absolute", margin: 0, width: "30vh",
                                     filter: "brightness(0.25) opacity(0.5)",
                                     imageRendering: "pixelated",}}/>
                            <p style={{zIndex: 1}}>Chiptunes</p>
                        </a>
                    </div>
                </div>

                {/*<div id="serviceDescriptionPanel" style={{position: "absolute", left: "5vw", top: "5vh",*/}
                {/*    width: "90vw", height: "90vh", margin: 0,*/}
                {/*    background: "#444444bb",}}><h2>lmaoe</h2>*/}
                {/*</div>*/}

                {this.state.showServiceDescriptionPanel &&
                this.GetServiceDetailsPanel()}
            </div>
        );
    }
}

export default ServicesPage;