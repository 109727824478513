import React from "react";
import MyPathNode from "./MyPathNode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlag, faArrowUp, faArrowDown, faAngleDoubleUp, faAngleDoubleDown} from "@fortawesome/free-solid-svg-icons";
import variables from '../App.scss';
import Select, { components } from "react-select";
import './MyPath.scss';

import MyPathNodeDatas from "Data and JSONs/My Path Node Datas";
import {StylizedPathNodeData, ImportanceTypes} from "Data and JSONs/My Path Node Datas";

// https://dev.to/oussel/how-to-use-conditional-rendering-with-animation-in-react-1k20
const mountedStyle = { animation: "inAnimation 250ms ease-in" };
const unmountedStyle = {
    animation: "outAnimation 270ms ease-out",
    animationFillMode: "forwards"
};  // TODO: Should be put in a separate, globally used file. Seems like it has to be a .js.

/*const ImportanceTypes = {
    Important: 1,
    Normal: 0,
    Unimportant: -1,
};*/

const selectImportanceOptions = [
    { value: ImportanceTypes.Normal, label: "Normal", icon: "", },
    { value: ImportanceTypes.Important, label: "Important Only",  icon: "", },
    { value: ImportanceTypes.Unimportant, label: "Thorough (Include Unimportant)", icon: "", },
];
const selectImportanceOptionsTest = [
    { value: ImportanceTypes.Normal, label: "Normal", },
    { value: ImportanceTypes.Important, label: "Important Only", },
    { value: ImportanceTypes.Unimportant, label: "Thorough (Include Unimportant)", },
];
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

// Move into separate file?
// https://stackoverflow.com/questions/58449403/how-to-add-an-icon-to-the-options-in-react-select
const { Option } = components;
const IconOption = props => (
    <Option {...props}>
        <img
            src={require('./' + props.data.icon)}
            style={{ width: 36 }}
            alt={props.data.label}
        />
        {props.data.label}
    </Option>
);

//const CreateArrayN = (N) => Array.from(Array(N).keys());  // From 0 to N-1
const CreateValueArrayN = (value, N) => new Array(N).fill(value);

class MyPath extends React.Component {
    constructor(props) {
        super(props);

        const inheritPositionAndSizeStyle = {position: "inherit", width: "inherit", height: "inherit",};

        //MyPathNodeDatas[0][0].style = {fontSize: "5vw"};
        console.log(MyPathNodeDatas[0][0].props.style);
        let stylizedFirstNodeData = StylizedPathNodeData({nodeData: MyPathNodeDatas[0], style: inheritPositionAndSizeStyle});
        console.log(stylizedFirstNodeData[0]);
        console.log(stylizedFirstNodeData[0].props.style);

        let stylizedNodeDatas = MyPathNodeDatas.map(nodeData =>
            StylizedPathNodeData({nodeData: nodeData, style: inheritPositionAndSizeStyle})
        );

        let nodeElementStyle = inheritPositionAndSizeStyle;
        /*let nodeElementsData = [
            //stylizedFirstNodeData,
            //stylizedNodeDatas[0],

            //[textElement, backdropClassName, importantType],
            [<div style={nodeElementStyle}>
                <p><u>🌱 Sprout of Interest</u></p>
                <p>Life</p>
                <p>2016 - 2018</p>
                <p>I decide to dabble in GameMaker</p>
                <p>Studio 2, Android Studio</p>
                <p>and WinForms to make games</p>
                <p>...</p>
                <p>Oops.</p>
            </div>, null, ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>👨‍🏫 Guidance</u></p>
                <p>Life</p>
                <p>2018</p>
                <p>When I studied in <a href="https://www.magshimim.cyber.org.il/">Magshimim</a>,</p>
                <p>and had to say goodbye to</p>
                <p>my lecturer, he left me a final tip:</p>
                <p>Make the games you've wanted.</p>
                <p>And so, I'm here today. Thanks, Nehoray :)</p>
            </div>, null, ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>🎩 Mr. Tophat - Birth</u></p>
                <p>Game</p>
                <p>2018 September</p>
                <p>Ideas spurted, and I was excited.</p>
                <p>I was going to make a small game,</p>
                <p>inpired by Cuphead and Undertale,</p>
                <p>and a hint of Mario.</p>
                <p>Endless concepts.</p>
            </div>, null, ImportanceTypes.Normal,],

            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/tutorialjam-rollovergold">
                    <p><u>🎓 Tutorial Jam</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2018 November</p>
                <p>I decided to join an event<br/>
                    called a
                    <i> </i><a href="https://en.wikipedia.org/wiki/Game_jam" className="tooltip-container">"game
                        jam"
                        <span className="tooltip-text">A time-limited game making competition.</span></a>.</p>
                <p>Found one with "tutorial" in its name, and jumped in.</p>
                <p>One of the best decisions of my life! (Even if the game's lame.)</p>
            </div>, null, ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>🎩 Mr. Tophat - Too Much</u></p>
                <p>Game - Unity, C#</p>
                <p>2019</p>
                <p>I love the idea of Mr. Tophat so much.</p>
                <p>... but alas, the idea was too grand.</p>
                <p>When I tried to program it, I could not.</p>
                <p>I was merely a beginner, and I wanted</p>
                <p>to make the next Undertale!</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>🛋️ Co-Op Jam</u></p>
                <p>Game - Unity, C#</p>
                <p>2019 July</p>
                <p>I joined a larger jam, this time!</p>
                <p>A month of development, I decided.</p>
                <p>Couch Game was designed with love, and...</p>
                <p>I've learnt to shrink my scope, once again.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>⌛ Timeless?</u></p>
                <p>Life</p>
                <p>2019 August</p>
                <p>I've been recruited to the army.</p>
                <p>My time has been limited severely.</p>
                <p>I was not content, but eventually...</p>
                <p>I decided it didn't matter.</p>
                <p>The dream is certainly not dead!</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>👀 His Universe</u></p>
                <p>Game - Unity, C#</p>
                <p>2019 September</p>
                <p>I've decided to continue pursuing game development.</p>
                <p>"The time may be halved, but I'll persist!"</p>
                <p>This time, little to no art, a tiny map, 15 minutes of gameplay.</p>
                <p>Overscoped. Wanted to make a whole story with a narrative system.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>👹 Myth of the Abducted</u></p>
                <p>Game - Unity, C#</p>
                <p>Status: 5% complete.</p>
                <p>2020 January</p>
                <p>Another attempt at a game.</p>
                <p>Overscoped, with both a story and a complex game mechanic.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>🔫 Gun Gladiator</u></p>
                <p>Game - Unity, C#</p>
                <p>2020 February</p>
                <p>My first impressive attempt at a game.</p>
                <p>No story, this time!</p>
                <p>And once again, less than 15 minutes of gameplay.</p>
                <p>The project was still quite overwhelming, sadly.</p>
                <p>Despite stopping, it was not the project's end.</p>
            </div>, null, ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>👖 I Need My Pants</u></p>
                <p>Game - Unity, C#</p>
                <p>2020</p>
                <p>An attempt to break out of the norm.</p>
                <p>Tried some 3D, for the fun of it.</p>
                <p>Practiced a story system.</p>
                <p>This was never completed.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>☄️EBF Clone</u></p>
                <p>Game - Unity, C#</p>
                <p>2020</p>
                <p>Ahh, Epic Battle Fantasy. How I adore this game!</p>
                <p>I've forever wanted to attempt making a similar game.</p>
                <p>So I tried, with the tutorials of Code Monkey.</p>
                <p>Tried to built the game over Code Monkey's downloadable project.</p>
                <p>This... was a hard project to follow.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://afells.itch.io/bop-placeholder*-/
            [<div style={nodeElementStyle}>
                <a href="https://afells.itch.io/bop-placeholder"><p>
                    <u>👨‍🚀 The Colonizer</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2020</p>
                <p>Another game jam..! AND, my first team!</p>
                <p>This was a very exciting jam, no longer than a week.</p>
                <p>I made much of the art, but also contributed to the code!</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>🕹️ Multi-Game</u></p>
                <p>Game - Unity, C#</p>
                <p>2020</p>
                <p>7 games, 7 days.</p>
                <p>I was to make tiny, tiny games!</p>
                <p>It was okay, until I tried to make Mario.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>🎩 Mr. Tophat, again!</u></p>
                <p>Game - Unity, C#</p>
                <p>2021 January</p>
                <p>With more experience under my belt, I retried.</p>
                <p>By now, my concepts were still growing, and became absolutely monumental.</p>
                <p>I managed a basic jump mechanic, but... no more, really.</p>
                <p>Lots of art and music, though!</p>
            </div>, null, ImportanceTypes.Normal,],

            /*https://blawnode.itch.io/tripletriangle*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/tripletriangle">
                    <p><u>▲ Triple Triangle</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 - 2022</p>
                <p>I have decided. NOOO more overscoping.</p>
                <p>None of that! No music, no pretty graphics, minimal sounds.</p>
                <p>Only a simple, mobile game, the likes of Ketchapp's games.</p>
                <p>This was a long process. But...</p>
                <p><b>I did it???</b></p>
            </div>, "backdrop-triple-triangle", ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>🍅 Men at Foods</u></p>
                <p>Game - Unity, C#</p>
                <p>2021</p>
                <p>Had a fun idea for a 3D game mechanic.</p>
                <p>Eventually unrealised.</p>
                <p>You throw stuff onto other people, like tomatoes,</p>
                <p>and they can catch them.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://github.com/blawnode/Mixed-Bag-s-game*-/
            /*https://isochi.itch.io/echoes-of-the-void*-/
            [<div style={nodeElementStyle}>
                <a href="https://isochi.itch.io/echoes-of-the-void">
                    <p><u>🔦 Echoes of the Void</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 July</p>
                <p>A small jam. I was the musician, for a change..!</p>
                <p>I'm very proud of my work. But eventually, I had</p>
                <p>to become a programmer too, again.</p>
                <p>Despite being too unfair, this game felt like a massive</p>
                <p>success, and was quite loved!</p>
            </div>, null, ImportanceTypes.Normal,],

            /*https://blawnode.itch.io/temporary-pizza-mens-game*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/temporary-pizza-mens-game">
                    <p><u>🚦 Light Speed Delivery</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 August</p>
                <p>The fabled Brackeys Jam!</p>
                <p>And, one of the best products I've been a part of!</p>
                <p>An artist, this time. We had two programmers,</p>
                <p>and this time, they managed fine without me.</p>
                <p>The art and sound got nice rankings!</p>
            </div>, null, ImportanceTypes.Important,],

            /*https://blawnode.itch.io/polykiller*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/polykiller">
                    <p><u>■ PolyKiller</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 September</p>
                <p>My first TriJam. This is a very fun kind of jam!</p>
                <p>3 hours. No more, no less.</p>
                <p>Our programmer went M.I.A, so I became it.</p>
                <p>Quite belatedly, sadly.</p>
                <p>The game's code was terribly behind in schedule.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>🔫 Gun Gladiator, Revisited!</u></p>
                <p>Game - Unity, C#</p>
                <p>2021 September</p>
                <p>I decided to mean business.</p>
                <p>After so many jams? "I'm DEFINITELY ready!"</p>
                <p>I was joined by a new friend, the musician.</p>
                <p>And I did the rest, mostly code and art.</p>
                <p>Frankly... I was still not ready. But, lots of progress was made, this time.</p>
            </div>, null, ImportanceTypes.Normal,],

            /*https://blawnode.itch.io/of-bananas*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/of-bananas">
                    <p><u>🍌 Of Bananas</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 October</p>
                <p>Another TriJam.</p>
                <p>I decided to experiment with Inkle's Ink.</p>
                <p>Worked shockingly perfectly, but the game was a bit...</p>
                <p>underwhelming, it seems.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>👋 Be My Guest</u></p>
                <p>Game - Godot, Python(/GDScript?)</p>
                <p>2021 November</p>
                <p>My first attempt at Godot.</p>
                <p>I decided to make a little RPG, to polish my coding skills.</p>
                <p>Tried to make a story system again, and a basic interaction system.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://blawnode.itch.io/spreading-the-cold*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/spreading-the-cold">
                    <p><u>❄️ Spreading the Cold</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 November</p>
                <p>The IGU Winter Jam! I am fond of it.</p>
                <p>I was joined by a digital friend and a stranger friend,</p>
                <p>and I made the code alone this time!</p>
                <p>Despite being a little broken, the game did okay!</p>
                <p>And the feats were somewhat impressive.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://blawnode.itch.io/codegunner*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/codegunner">
                    <p><u>🤖 Code Gunner</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2021 December</p>
                <p>The JLM Jam. My first Israeli jam..!</p>
                <p>I did not attend physically, but I couldn't pass the opportunity.</p>
                <p>I made a little game, and it was great!</p>
                <p>There was no voting and I didn't hear about how my game was received,</p>
                <p>But I was very satisfied.</p>
            </div>, null, ImportanceTypes.Normal,],

            [<div style={nodeElementStyle}>
                <p><u>🛏️ I Leave the Bed</u></p>
                <p>Game - Godot, Unity, C#</p>
                <p>2022 February</p>
                <p>A Unity RPG.</p>
                <p>A more personal game.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://github.com/blawnode/The-Rock-Simulator*-/  // TODO
            [<div style={nodeElementStyle}>
                <p><u>🧙 Everhood's Custom Battle Contest</u></p>
                <p>Game - Unity</p>
                <p>2022 February</p>
                <p>Ah, Everhood. Love it to bits.</p>
                <p>We were to mod the game, pretty much.</p>
                <p>Each team made a musical battle!</p>
                <p>My submission, The Cackler, was not very well received.</p>
                <p>But I was, and still am, so proud of it.</p>
            </div>, null, ImportanceTypes.Normal,],

            /*https://blawnode.itch.io/low-disk-space*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/low-disk-space">
                    <p><u>👾 Low Disk Space</u></p></a>
                <p>Game - Godot, Python(/GDScript?)</p>
                <p>2022 March</p>
                <p>The GDC jam.</p>
                <p>My first finished project made with Godot!</p>
                <p>Teamed with a programmer, and another, that could not join us.</p>
                <p>I did the art, music, and some of the programming.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://blawnode.itch.io/adrift-jam*-/
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/adrift-jam">
                    <p><u>🔋 A Jam with BN #1</u></p></a>
                <p>Game - Unreal Engine</p>
                <p>2022 May</p>
                <p>The most meaningful event I've ever had the pleasure</p>
                <p>of being a part of. I hosted a game jam!</p>
                <p>5 friends and strangers joined me, and we made a slightly broken,</p>
                <p>but beloved, 2D game.</p>
                <p>First (and only) experience with Unreal Engine!</p>
            </div>, "backdrop-adrift", ImportanceTypes.Important,],

            /*https://blawnode.itch.io/adrift, https://store.steampowered.com/app/2606610/Adrift_Program/*-/
            [<div style={nodeElementStyle}>
                <a href="https://store.steampowered.com/app/2606610/Adrift_Program/">
                    <p><u>🔋 Adrift Program</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2022 May - Present</p>
                <p>The "A Jam with BN #1" jam was great fun.</p>
                <p>The team decided to continue developing the game!</p>
                <p>We have been making and polishing this game for <i>months</i>, now!</p>
                <p>Couldn't be more proud of this creation.</p>
            </div>, "backdrop-adrift", ImportanceTypes.Important,],


            /*TODO: <iframe src="https://discord.com/widget?id=1029820838188421130&theme=dark"
                        width="350" height="500" allowtransparency="true" frameborder="0"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>*-/

            /*https://github.com/blawnode/The-Rock-Simulator*-/
            [<div style={nodeElementStyle}>
                <a href="https://github.com/blawnode/The-Rock-Simulator">
                    <p><u>⛰️The Rock Simulator</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2022 July</p>
                <p>Another attempt at an EBF clone.</p>
                <p>But this time... it was <i>flawless</i>.</p>
                <p>I published this project as an open source GitHub repository!</p>
            </div>, null, ImportanceTypes.Normal,],

            /*https://github.com/blawnode/WDYM*-/
            [<div style={nodeElementStyle}>
                <a href="https://github.com/blawnode/WDYM">
                    <p><u>👊 WHAT DO YOU MEAN?!</u></p></a>
                <p>Game - Unity, C#</p>
                <p>2022 September</p>
                <p>Another TriJam, and I delivered BIG time!</p>
                <p>With art and music assets ready to be unleashed, I was pumped.</p>
                <p>Made a tiny, PUNCHing game, that looks awesome.</p>
                <p>... wish it scored higher though.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            /*https://github.com/blawnode/Blawsole-and-SprintRPG*-/
            [<div style={nodeElementStyle}>
                <a href="https://github.com/blawnode/Blawsole-and-SprintRPG">
                    <p><u>🕹️ Blawsole & SprintRPG</u></p></a>
                <p>Game - PyGame, Python</p>
                <p>2022 November</p>
                <p>I adore Nitrome's SprintRPG.</p>
                <p>... So I remade it. Nothing professional, though!</p>
                <p>A little PyGame console, with this fast-paced,
                    easy to modify key game.</p>
            </div>, null, ImportanceTypes.Unimportant,],

            [<div style={nodeElementStyle}>
                <p><u>⚛ freeCodeCamp Course</u></p>
                <p>2022 June - 2022 December</p>
                <p>Education</p>
                <p>I have officially complete freeCodeCamp's course!:</p>
                <p>"Front End Development Libraries".</p>
                <p>I even got a neat <a
                    href="https://www.freecodecamp.org/certification/idowaisbart/front-end-development-libraries">
                    certification</a>!</p>
                <p>Meaning: I studied React and other libraries,</p>
                <p>And made several little web projects.</p>
            </div>, "backdrop-freecodecamp", ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <p><u>⚛ Personal Website</u></p>
                <p>2022 September - Present</p>
                <p>Webpage - React.js</p>
                <p>After studying in freeCodeCamp's course, I felt ready to make a GOOD website!</p>
                <p>And so, I've begun working.</p>
                <p>Lo and behold!:</p>
                <p>You are seeing it right now.</p>
            </div>, "backdrop-website", ImportanceTypes.Important,],

            [<div style={nodeElementStyle}>
                <a>
                    <p><u>🟨 AEBF (Another Epic Battle Fantasy)</u></p></a>
                <p>2023 April - 2023 December</p>
                <p>Game, Server - Unity, .NET API, MS SQL Server</p>
                <p>My finishing project for the Open University's OOP workshop!</p>
                <p>Uses The Rock Simulator RPG framework, as well as is an upgrade to it!</p>
                <p>A flexible, extendable game with a full-stack modding and user system.</p>
                <p>Heavily inspired by Epic Battle Fantasy, by Matt Roszak.</p>
            </div>, "backdrop-aebf", ImportanceTypes.Important,],

            // https://blawnode.itch.io/insomniac-moles
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/insomniac-moles">
                    <p><u>🕳 Insomniac Moles</u></p></a>
                <p>2023 July</p>
                <p>Game - Unity</p>
                <p>A collab with a friend, made in 3 hours for the GMTK Game Jam 2023!</p>
                <p>A reverse Whack-A-Mole game: You play as the moles, and hide for your lives!</p>
                <p>But not for too long. Your non-existent eyes need some sunlight!</p>
                <p>Played with a numpad.</p>
            </div>, "backdrop-insomniac-moles", ImportanceTypes.Important,],

            // https://blawnode.itch.io/perception-to-conquer-evil
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/perception-to-conquer-evil">
                    <p><u>⚔ Perception: To Conquer Evil</u></p></a>
                <p>2023 August</p>
                <p>Game - Unity, C#, FMod</p>
                <p>A submission to the IGU Summer Jam 2023, made in 14 days!</p>
                <p>A small slashing action game, with some story and voice acting!</p>
                <p>Involves 3 "boss" fights, and some easter eggs.</p>
                <p>Find me!</p>
            </div>, "backdrop-perception", ImportanceTypes.Important,],

            // https://blawnode.itch.io/blank-space
            [<div style={nodeElementStyle}>
                <a href="https://blawnode.itch.io/blank-space">
                    <p><u>💡 <b>_</b> Space</u></p></a>
                <p>2024 January</p>
                <p>Game - Unity, C#</p>
                <p>A personal jam game for the IGU Winter Jam 2024. (Won 3rd place out of 17!)</p>
                <p>While not all features were complete, polishing was at an all time high!</p>
                <p>Story-driven, short and simple. With a hint of shooting.</p>
                <p>Destined to be made an even bigger game.</p>
            </div>, "backdrop-_-space", ImportanceTypes.Normal, "_ Space"]
        ];*/
        let nodeElementsData = stylizedNodeDatas;
        let nodeElementsToShowIndexes = CreateValueArrayN(true, nodeElementsData.length);
        this.state = {titleList: [], titleListElement: <div/>, lastTitle: "",
            selectedImportance: ImportanceTypes.Normal,
            nodeIsRightMap: {}, nodeElementsData: nodeElementsData,
            nodeElementsToShowIndexes: nodeElementsToShowIndexes,
            generatedPathNodes: <div id="unchangedGeneratedPathNodes"/>,
            arrowsShouldAppear: false, areArrowsMounted: false,
        };

        this.GeneratePathNodes = this.GeneratePathNodes.bind(this);
        this.MakeNodeTitleListElement = this.MakeNodeTitleListElement.bind(this);
        this.OnSelectedNodeTitle = this.OnSelectedNodeTitle.bind(this);
        this.OnSelectImportance = this.OnSelectImportance.bind(this);
        this.InsertTitleToParent = this.InsertTitleToParent.bind(this);  // Holy shit, it worked: https://stackoverflow.com/questions/48407785/react-pass-function-to-child-component
        this.TellParentIfVisible = this.TellParentIfVisible.bind(this);
    }

    componentDidMount() {
        this.SetupScrollArrowsBehaviour();
        this.setState({generatedPathNodes: this.GeneratePathNodes()});
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.state !== prevState){
            // The state has changed.

            // https://stackoverflow.com/questions/59273550/reactjs-check-difference-between-this-state-and-nextstate
            if(this.state.generatedPathNodes === prevState.generatedPathNodes){
                // It's not state.generatedPathNodes that has changed. It's safe to update the generated path nodes!
                this.setState((prevState) => ({generatedPathNodes: this.GeneratePathNodes()}));
            }
        }
    }

    // https://dev.to/oussel/how-to-use-conditional-rendering-with-animation-in-react-1k20
    SetupScrollArrowsBehaviour(){
        document.onscroll = (event)=>{
            //console.log("Document has moved.");

            let firstTitle = this.state.titleList[0],
                lastTitle = this.state.titleList[this.state.titleList.length-1];

            let firstPathNode = document.getElementById(firstTitle);
            if(firstPathNode === null) return false;  // Nodes haven't loaded yet?
            //let lastPathNode = document.getElementById(lastTitle);
            //if(lastPathNode === null) return false;  // Should be impossible.

            let headerHeight = parseInt(variables.headerHeight, 10);  // https://stackoverflow.com/questions/3024084/jquery-javascript-how-do-i-convert-a-pixel-value-20px-to-a-number-value-2
            let windowY = window.scrollY;
            let firstPathNodeY = firstPathNode.offsetTop;
            let arrowsShouldAppear = windowY > firstPathNodeY - headerHeight;
            this.setState({lastTitle: lastTitle,});
            this.setState({areArrowsMounted: arrowsShouldAppear});
            if(arrowsShouldAppear) this.setState({arrowsShouldAppear: arrowsShouldAppear}); // Render element immediately
            // ELSE, let the animation's end cause the element to unrender.
        };
    }

    // TODO: Happens every frame. Definitely not efficient...
    //          Should happen with every state update or something.
    GeneratePathNodes() {
        let nodeElementsData = this.state.nodeElementsData;
        let lastElementIndex = nodeElementsData.length - 1;
        let nodeIsRightMap = {};

        let visibleElementCounter = 0;

        let nodeElements = nodeElementsData.map((element, i) => {
            let textElement = element[0];
            let backdropClassName = element[1];
            let importanceType = element[2];

            nodeIsRightMap[i] = (visibleElementCounter) % 2 === 1;
            visibleElementCounter++;

            return <MyPathNode textElement={textElement}
                               isRight={this.state.nodeIsRightMap[i]}
                               last={i === lastElementIndex}
                               backdropClassName={backdropClassName == null ? "backdrop-generic" : backdropClassName}
                               importanceType={importanceType}
                               selectedImportance={this.state.selectedImportance}
                               InsertTitleToParent={this.InsertTitleToParent}
                               TellParentIfVisible={[this.TellParentIfVisible, i]}
                               key={i}/>;
        });

        return <>{nodeElements}</>;
    }

    // TODO: Is this crucial? Most probably. Should check.
    TellParentIfVisible(index, visibility, titleText){
        let nodeElementsToShowIndexes = this.state.nodeElementsToShowIndexes;
        if(nodeElementsToShowIndexes[index] === visibility) return;

        // There was a difference!! Update what's needed.
        nodeElementsToShowIndexes[index] = visibility;

        this.setState(previousState => ({nodeElementsToShowIndexes: nodeElementsToShowIndexes,}));
        this.UpdateNodesRightAlignment();
        this.setState(previousState => ({titleListElement: this.MakeNodeTitleListElement(previousState)}));
    }

    UpdateNodesRightAlignment(){
        let nodeElementsData = this.state.nodeElementsData;
        let nodeElementsToShowIndexes = this.state.nodeElementsToShowIndexes;
        let nodeIsRightMap = this.state.nodeIsRightMap;
        let visibleElementCounter = 0;

        for(let i = 0; i < nodeElementsData.length; i++){
            if (!nodeElementsToShowIndexes[i]) continue;
            nodeIsRightMap[i] = (visibleElementCounter) % 2 === 1;
            visibleElementCounter++;
        }
        this.setState(previousState => ({nodeIsRightMap: nodeIsRightMap}));  // Impossible in render().
        // That ^ line used a function to allow it to be called along with other setState's, like in OnSelectImportance.
    }

    // Passed as a prop to the MyPathNode's.
    // Fires only once per child, in their constructor()'s.
    InsertTitleToParent(title){
        this.setState(previousState => ({titleList: [...(previousState.titleList), title]}));
        this.setState(previousState => ({titleListElement: this.MakeNodeTitleListElement(previousState)}));
    }

    // ASSUMPTION: state.titleList is not null.
    MakeNodeTitleListElement(state) {
        if (state === undefined)
            throw new Error("MakeNodeTitleListElement's <state> must not be undefined.");

        let nodeElementsToShowIndexes = this.state.nodeElementsToShowIndexes;
        return <select id="TitleSelect" style={{width: "20vw"}} onChange={this.OnSelectedNodeTitle}>
            <option value="">- Select event -</option>
            {state.titleList.map((title, i) => {
                let optionalOverrideTitle = state.nodeElementsData[i][4];
                if(state.nodeElementsData[i][4] != null)
                {
                    // Useful when the innermost element, that's meant to be the text, is complex. Like: "<b>_</b> Space."
                    title = optionalOverrideTitle;
                }
                //console.log("New option: " + title);
                return (nodeElementsToShowIndexes[i]) ?
                    <option value={title} key={title}>{title}</option>
                    : null;})}
        </select>;
    }

    OnSelectImportance(event){
        // Can be either of: normal, important, thorough
        let importance = event.target.value;
        if(importance === this.state.selectedImportance) return;

        this.setState(previousState => ({selectedImportance: importance}));

        this.UpdateNodesRightAlignment();
    }

    OnSelectedNodeTitle(event){
        let title = event.target.value;
        if(title === "")
            return;
        //console.log("Selected node title: " + title);
        this.ScrollToID(title);
        let titleSelect = document.getElementById("TitleSelect");
        titleSelect.value = "";
    }

    // <id> can be, for example: 'contact us', if there's an element with this id.
    ScrollToID(id) {
        const element = document.querySelector( '[id="' + id + '"]' );  // https://stackoverflow.com/questions/63551929/queryselector-is-not-recognising-id-with-space
        element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    render() {
        return (
            <div>
                <div style={{paddingBottom: "100px"}}/>

                {this.state.titleListElement}

                {/* TODO: Implement the importances, such that if Important is chosen,
                        the direction of the path nodes adjust.
                        ATM, they certainly don't.*/}
                <div style={{width: "2vw", display: "inline-block",}}/>
                <select onChange={this.OnSelectImportance} style={{width: "20vw",}}>
                    <option value={ImportanceTypes.Normal}>Normal</option>
                    <option value={ImportanceTypes.Important}>(!) Important Only</option>
                    <option value={ImportanceTypes.Unimportant}>(Ɐ) Thorough (Include Unimportant)</option>
                </select>
                {/*<Select onChange={this.OnSelectImportance}>*/}
                {/*    defaultValue={selectImportanceOptions[0]}*/}
                {/*    options={selectImportanceOptions}*/}
                {/*    components={{ Option: IconOption }}*/}
                {/*</Select>*/}
                {/*<Select>*/}
                {/*    /!*defaultValue={selectImportanceOptionsTest[0]}*!/*/}
                {/*    options={options}*/}
                {/*</Select>*/}
                {/*<div id="startPath"/>*/}

                {/*{this.GeneratePathNodes()}*/}
                {this.state.generatedPathNodes}

                {/* TODO: ↓ The "Go to bottom/top" buttons. Should scroll to the first and last node ID's, like in this.scroll(). ↓ */}
                {(this.state.arrowsShouldAppear
                    && <div className="mypath-arrows-holder" style={this.state.areArrowsMounted ? mountedStyle : unmountedStyle}
                            onAnimationEnd={() => { if (!this.state.areArrowsMounted) this.setState({arrowsShouldAppear: false}) }}>
                    <div className="path-arrow-button" style={{top: "5vh", position: "absolute",}}>
                        <a href={"#" + this.state.titleList[0]} > {/*TODO OPTIONAL: Make a component out of this? Much more readable.*/}
                            <FontAwesomeIcon icon={faArrowUp} />
                        </a>
                        {/*TODO: This second arrow's existence causes an issue of the collision shape.
                            It's imperfect. There's an area where you cannot click.*/}
                        <div className="path-arrow-button-small" style={{position: "absolute", bottom: "60%", left: "60%", }}>
                            <a href={"#" + this.state.titleList[0]} >
                                <FontAwesomeIcon icon={faAngleDoubleUp} />
                            </a>
                        </div>
                    </div>
                    <div className="path-arrow-button" style={{bottom: "5vh", position: "absolute",}}>
                        <a href={"#" + this.state.lastTitle}> {/*TODO OPTIONAL: Make a component out of this? Much more readable.*/}
                            <FontAwesomeIcon icon={faArrowDown} />
                        </a>
                        <div className="path-arrow-button-small" style={{position: "absolute", bottom: "60%", left: "60%", }}>
                            <a href={"#" + this.state.lastTitle} >
                                <FontAwesomeIcon icon={faAngleDoubleDown} />
                            </a>
                        </div>
                    </div>
                </div>)}
            </div>
        );
    }
}

export default MyPath;