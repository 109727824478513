import React from "react";
import logo from "../../logo.svg";
import UserData from "../Profiles/UserData";
import ItchArticle from "../ItchArticle";

class DemoPage extends React.Component {
    componentDidMount() {
        document.title = "Demo - Ido Waisbart";
    }

    render() {
        return (
            <div className="middle-inner-part-margin"
                 style={{display: "flex", flexDirection: "column", alignItems: "center",}}>
                <h1>Demo page</h1>
                <h6>This page showcases interesting technologies related to web development.</h6>
                <hr/>

                <p>Trial of the mask???</p>
                <iframe width="500" height="281" src="//www.youtube.com/embed/OB2hPp_rPc4" frameBorder="0"
                        allowFullScreen="" title="Trial of the Mask" />
                <br/>

                <ItchArticle title="August Session Blog - Team Member Departure" creator="BN" platform="itch.io"
                             link="https://blawnode.itch.io/adrift/devlog/421406/august-session-blog-team-member-departure"
                             font="terminal-1"
                             textElements={[
                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODUucG5n/original/sxv%2BrZ.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     Ahh, continued jam games.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     Once that happens, as the team gives the thumbs up to continue working?
                                     The feeling is golden.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     Our team of 6 members has since gone and done many things:
                                     Start from Unreal, transfer the game to Unity, design and dream of what could be
                                     made,<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     And so far, 5 phases were conducted, and 5 game versions were made!</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     All stemmed from a very, <b> very </b> casual and small game jam.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0OTQucG5n/original/UXEj%2FF.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     Since, the team has fallen apart a bit. 2 members left to continue their journey,
                                     and another did too, slowly.
                                     And now, it's me (Blawnode), redsti and NoStereo.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0OTAucG5n/original/oAfR7p.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     It's still been fun since, but it's obviously not the same.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzUucG5n/original/GCyCMN.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     The remaining members are trying our best to deliver a game to be remembered.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0ODYucG5n/original/zGlzd1.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     <b>... is there anything to learn from the experience? </b>
                                     I believe so.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzkucG5n/original/HsEOyk.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     The root cause of the team's rupture was not that the project got stale too fast,
                                     or
                                     that it was not promising enough.
                                     It was not fights within the team, nor doubts about the product.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     In fact... <b> I believe it went perfectly fine.</b></p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     I believe that the project's state is just great. It's all just... expectable.
                                     You shouldn't go into a months-long project, reviving a super-casual team, and
                                     expect it to be whole till the very end.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0ODUucG5n/original/sxv%2BrZ.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     Of course not! Sometimes, life causes or forces changes in our journeys'
                                     trajectories.
                                     And it's just fine.
                                     Especially within the context of this casual project.</p>,

                                 <p><img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                         alt="Blawnode Expression"/>&nbsp;
                                     My point? Life will interfere with your teams' plans, and it is something that
                                     needs
                                     to be acknowledged, and accepted.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0ODcucG5n/original/MEECNx.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     If you do, the adventure will be much more tolerable. I insist on that.
                                     Remain positive, no matter the departures of the team members.<br/>
                                     <img src="https://img.itch.zone/aW1nLzk5MDY0NzcucG5n/original/9Ga6zX.png"
                                          alt="Blawnode Expression"/>&nbsp;
                                     They sure would prefer that you would persist. :)</p>]}
                />

                <UserData/>

                {/*<Comments/>*/  /*A comments section.*/}
                <div style={{margin: "10vh 0"}}>
                    <form>
                        <input type="textbox" placeholder="Comment..."/>
                    </form>
                </div>

                {/*<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6984609427391721472"
                            height="429" width="504" frameBorder="0" allowFullScreen="" title="Embedded post"/>*/}
                {/*<iframe style={{border: 0, width: "200px", height: "314px",}}
                        src="https://bandcamp.com/EmbeddedPlayer/track=2935833691/size=large/bgcol=333333/linkcol=9a64ff/tracklist=false/transparent=true/"
                        seamless>
                    <a href="https://blawnode.bandcamp.com/track/a-mistake-contest-version">
                        A Mistake (Contest Version) by Blawnode
                    </a>
                </iframe>*/}
            </div>
        );
    }
}

export default DemoPage;