// TODO: Uses methods identical to those in UserData.js. Don't just duplicate code!
export function validateSignUpParameters(username, password, email){
    let isValid, errorMessage;
    for(const [parameter, validator] of [[username, validateUsername], [password, validatePassword], [email, validateEmail]]){
        let test = validator(parameter);
        [isValid, errorMessage] = validator(parameter);
        if(!isValid) return [false, errorMessage];
    }
    return [true, null];
}

// Requirements:
//  -   8 to 32 characters.
//  -   Must start with an alphabet.
//  -   Only alphabet and/or numbers.
export function validateUsername(username) {
    let length = username.length;
    if(length < 8 || length > 32){
        let errorMessage = "Invalid username: Length must be between 8 and 32 characters.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }
    // https://javascript.plainenglish.io/javascript-check-if-string-contains-only-letters-and-numbers-5dce23ddfecf
    if(!/^[A-Za-z0-9_]*$/.test(username)){
        let errorMessage = "Invalid username: The username must have only alphabet or digits.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }

    // js has no 'char' - string.at() returns a string.
    function isCharAlpha(char){
        //return /[A-Za-z]/.test(char);

        // Faster?
        // https://stackoverflow.com/questions/9862761/how-to-check-if-character-is-a-letter-in-javascript
        let c = char.charCodeAt(0);
        return (c >= 65 && c < 91) || (c >= 97 && c < 123);
    }

    if(!isCharAlpha(username.at(0))){
        let errorMessage = "Invalid username: The username's first character must be alphabetic.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }

    return [true, null];

    // From UserData.js
    /*fetch('http://localhost:3001')
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log("data:");
            console.log(data);
            data.forEach((record) => {
                if(record.username === "?"){
                    console.log("hey yo wtf");
                    return false;
                }
            });
        });
    return true;*/
}

// Requirements:
//  -   8 to 32 characters.
//  -   Must start with an alphabet.
//  -   Only alphabet, numbers and/or special characters (These: _!@#$%&*).
export function validatePassword(password) {
    let length = password.length;
    if(length < 8 || length > 32){
        let errorMessage = "Invalid password: Length must be between 8 and 32 characters.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }
    // https://javascript.plainenglish.io/javascript-check-if-string-contains-only-letters-and-numbers-5dce23ddfecf
    if(!/^[A-Za-z0-9_!@#$%&*]*$/.test(password)){
        let errorMessage = "Invalid password: The password must have only alphabet, digits," +
            "or one of these special characters: { _!@#$%&* }.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }

    function hasLowerCase(str) {
        return (/[a-z]/.test(str));
    }
    function hasUpperCase(str) {
        return (/[A-Z]/.test(str));
    }
    function hasDigit(str) {
        return (/[0-9]/.test(str));
    }

    if(!hasLowerCase(password)){
        let errorMessage = "Invalid password: The password must have at least one lowercase character.";
        return [false, errorMessage];
    }
    if(!hasUpperCase(password)){
        let errorMessage = "Invalid password: The password must have at least one uppercase character.";
        return [false, errorMessage];
    }
    if(!hasDigit(password)){
        let errorMessage = "Invalid password: The password must have at least one digit.";
        return [false, errorMessage];
    }

    return [true, null];

    // From UserData.js
    /*fetch('http://localhost:3001')
        .then(response => {
            return response.json();
        })
        .then(data => {
            console.log("data:");
            console.log(data);
            data.forEach((record) => {
                if(record.username === "?"){
                    console.log("hey yo wtf");
                    return false;
                }
            });
        });
    return true;*/
}

// TODO: Move to UserData.js, or some new file UserValidation.js
// Requirements:
//  -   8 to 32 characters.
//  -   Must follow the format: str@str.str.
//  -   Only alphabet and/or numbers.
export function validateEmail(email) {
    let length = email.length;
    if(length < 8 || length > 32){
        let errorMessage = "Invalid email: Length must be between 8 and 32 characters.";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }

    // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
    if(!/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])*$/.test(email.toLowerCase())){
        let errorMessage = "Invalid email: The email must have only alphabet, digits or certain special digits, and follow the format: name@place.thing." +
            "The allowed special digits are: !#$%&'*+/=?^_`{|}~-";
        //console.log(errorMessage);
        //this.setState({userValidationErrorMessage: errorMessage});
        return [false, errorMessage];
    }

    return [true, null];
}

export default {validateSignUpParameters, validateUsername, validatePassword, validateEmail};