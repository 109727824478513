import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMusic, } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faItchIo, faLinkedin,
    faTwitter, faYoutube,
    faBandcamp, faSoundcloud,
} from '@fortawesome/free-brands-svg-icons'
import {VideosAndScreenshotsViaItchIo} from "../../VideosAndScreenshots_Itch";

class AdriftPage extends React.Component {
    constructor(props) {
        super(props);

        this.MusicLinkToLogo = this.MusicLinkToLogo.bind(this);
    }

    componentDidMount() {
        document.title = "Adrift Program - Ido Waisbart"
    }

    MusicLinkToLogo(link){
        link = link.toLowerCase();
        if (link.includes("bandcamp"))
            return faBandcamp;
        if (link.includes("soundcloud"))
            return faSoundcloud;
        // TODO OPTIONAL: Include Newgrounds logo option
        return faMusic;
    }

    render() {
        return (
            <div className="middle-inner-part-margin-clamped">
                <div style={{display: "flex", alignItems: "center", width: "inherit", height: "80vh",}}>
                    <div style={{width: "55%", height: "100%",}}>
                        <div style={{height: "70%",}}>
                            <img src="/Images/My Games/Adrift/Adrift%20Logo%20V2.webp"
                                 alt="Adrift Program Logo"
                                 style={{height: "10vh",}}/>
                            <div style={{height: "2vh",}}/>
                            <i><h6 style={{fontSize: "0.7em",}}>"Supply energy to many spaceships in this small, chill
                                resource and time management
                                game.<br/>
                                A Cooked-Like, if you will!"
                            </h6></i>
                            <h6 style={{fontSize: "0.7em",}}>A game made by: BN (me!), Redsti, NoStereo, KingKelpo, sound_digitator and Nazsgull.</h6>
                            <hr/>
                            <span style={{fontSize: "0.425em",}}>
                                <p style={{fontSize: "inherit",}}>Inspired by Overcooked, we made a time management game in space.</p>
                                <p style={{fontSize: "inherit",}}>Our take on the formula is a single-player, short and calmer game.</p>
                                <p style={{fontSize: "inherit",}}>Charge batteries, filter fuel and maintain the spaceships' energy,
                                    in this short, cute and (hopefully) chill endeavour!</p>
                                <p style={{fontSize: "inherit",}}>Accompanied by nice pixel graphics, and orchestral-chiptune music.</p>
                            </span>
                        </div>
                        <div style={{height: "2.5vh"}}/>
                        <div>
                            <div className="social-media-container"
                                 style={{width: "50%", margin: "auto",}}>
                                <a href="https://blawnode.itch.io/" style={{color: "#fa5c5c",}}><FontAwesomeIcon
                                    icon={faItchIo}/></a>
                                <a href="https://twitter.com/AdriftProgram"><FontAwesomeIcon icon={faTwitter}
                                                                                             style={{color: "#1da1f2"}}/></a>
                                <a href="https://www.newgrounds.com/playlists/view/ea6eecff3a80210f83e80b936ad33db0">
                                    <FontAwesomeIcon icon={this.MusicLinkToLogo("https://www.newgrounds.com/playlists/view/ea6eecff3a80210f83e80b936ad33db0")}
                                                     style={{color: "#ff8000"}}/></a>
                            </div>
                            <br/>
                            <div className="social-media-container social-media-container-smaller"
                                 style={{width: "25%", margin: "auto",}}>
                                <a href="https://github.com/blawnode/Adrift-Issue-Tracking"
                                   style={{color: "#6e5494",}}><FontAwesomeIcon icon={faGithub}/></a>
                                <a href="mailto:adriftprogram@gmail.com" style={{color: "#fff",}}><FontAwesomeIcon
                                    icon={faEnvelope}/></a>
                                {/*<a href="https://m.youtube.com/channel/UCldotnKAENFMJRmH9esOqxA"><FontAwesomeIcon icon={faYoutube}/></a>*/}
                                {/*<a href="https://soundcloud.com/blawnode"><FontAwesomeIcon icon={faSoundcloud}/></a>*/}
                                {/*<a href="https://blawnode.bandcamp.com/"><FontAwesomeIcon icon={faBandcamp}/></a>*/}
                            </div>
                        </div>
                    </div>
                    <div style={{width: "5%",}}/>
                    <div style={{
                        width: "40%", height: "90%",
                        display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center",
                    }}>
                        <VideosAndScreenshotsViaItchIo getScreenshotsFrom="https://blawnode.itch.io/adrift"
                                              style={{width: "100%", height: "56.25%",}}/>
                        <iframe title="Adrift - Itch Page" frameBorder="0" style={{width: "60%",}}
                                src="https://itch.io/embed/1538958?linkback=true&amp;border_width=0&amp;bg_color=14182e&amp;fg_color=dfe0e8&amp;link_color=f0b541&amp;border_color=41455b">
                            <a href="https://blawnode.itch.io/adrift">Adrift Program by BN, Redsti, NoStereo</a>
                        </iframe>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdriftPage;